import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { toggleModal } from '../redux/modal.slicer';
import { data } from '../components/data/Data';
import playClick from '../assets/mp3/mixkit-modern-technology-select-3124.wav';

const SkillsPage = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const audioRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  return (
    <div className="skills">
      <audio ref={audioRef} src={playClick} style={{ display: 'none' }} />
      <div className="welcome">
        <div className="left"></div>

        <div className="central">
          <h1>{data[language].skills[0]}</h1>
        </div>

        <div className="right"></div>
      </div>

      <div className="moon"></div>

      <div className="planet-holder">
        <div className="jupiter"></div>
        <div className="saturn"></div>
      </div>

      <div className="holder container">
        <div className="box" id="react">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/react"
          >
            React
          </Link>
        </div>
        <div className="box" id="redux">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/redux"
          >
            Redux
          </Link>
        </div>
        <div className="box" id="javascript">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/javascript"
          >
            JavaScript
          </Link>
        </div>
        <div className="box" id="ajax">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/ajax"
          >
            AJAX
          </Link>
        </div>
        <div className="box" id="node">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/node"
          >
            Node
          </Link>
        </div>
        <div className="box" id="php">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/php"
          >
            PHP
          </Link>
        </div>
        <div className="box" id="mysql">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/mysql"
          >
            MySQL
          </Link>
        </div>
        <div className="box" id="mongo">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/mongo"
          >
            MongoDB
          </Link>
        </div>
        <div className="box" id="tailwind">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/tailwind"
          >
            Tailwind
          </Link>
        </div>
        <div className="box" id="sass">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/sass"
          >
            SASS
          </Link>
        </div>
        <div className="box" id="bootstrap">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/bootstrap"
          >
            Bootstrap
          </Link>
        </div>
        <div className="box" id="css">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/css"
          >
            CSS
          </Link>
        </div>
        <div className="box" id="html">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/html"
          >
            HTML
          </Link>
        </div>
        <div className="box" id="git">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/git"
          >
            Git & Github
          </Link>
        </div>
        <div className="box" id="flexbox">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/flexbox"
          >
            Flexbox
          </Link>
        </div>
        <div className="box" id="contact-form">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/contact-form"
          >
            Contact Form
          </Link>
        </div>
        <div className="box" id="responsive">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/responsive"
          >
            Responsive Design
          </Link>
        </div>
        <div className="box" id="seo">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/seo"
          >
            SEO
          </Link>
        </div>
        <div className="box" id="wordpress">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/wordpress"
          >
            WordPress
          </Link>
        </div>
        <div className="box" id="affinity">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/affinity"
          >
            Affinity Designer
          </Link>
        </div>
        <div className="box" id="hosting">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/hosting"
          >
            Hosting Setup
          </Link>
        </div>
        <div className="box" id="english">
          <Link
            onClick={() => dispatch(toggleModal(true), audioRef.current.play())}
            to="/skills/certificate/english"
          >
            English language
          </Link>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default SkillsPage;
