import React, { useEffect, useState } from 'react';
import Modal from '../components/modal/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteUserAccount } from '../services/user.service';
import { removeUserFromLocalStorage } from '../services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../redux/user.slicer';
import { data } from '../components/data/Data';

const DeleteAccountPage = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.hasOwnProperty('maxi_portfolio_user')) {
      return navigate('/comments');
    } else {
      setUser(JSON.parse(localStorage.getItem('maxi_portfolio_user')));
    }
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [navigate, language, languageStore]);

  const deleteAccount = () => {
    deleteUserAccount(user.email)
      .then((res) => {
        removeUserFromLocalStorage();
        dispatch(removeUser());
        toast.success(res.data.message);
        navigate('/comments');
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  return (
    <Modal>
      <div className="delete-account-page text-center">
        <h1 className="fs-4 mb-4">{data[language].delete_acc[0]}</h1>
        <div className="confirm-register-holder">
          <h2 className="fs-5 text-light">{data[language].delete_acc[1]}</h2>
          <button
            onClick={() => deleteAccount()}
            className="btn btn-danger my-5"
          >
            {data[language].delete_acc[2]}
          </button>
        </div>
        <Link
          className="btn btn-outline-secondary form-control mt-3"
          to="/user-account"
        >
          {data[language].delete_acc[3]}
        </Link>
      </div>
    </Modal>
  );
};

export default DeleteAccountPage;
