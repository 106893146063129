import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUser, setUserToLocalStorage } from '../../services/auth.service';
import { saveUser } from '../../redux/user.slicer';
import { FaEye } from 'react-icons/fa';
import { data } from '../data/Data.js';

const EmailLogin = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [signInObj, setSignInObj] = useState({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
    const animateLabel = () => {
      const labels = document.querySelectorAll('.form-control-login label');
      labels.forEach(
        (label) =>
          (label.innerHTML = label.innerText
            .split('')
            .map(
              (letter, idx) =>
                `<span style="transition-delay:${idx * 50}ms">${letter}</span>`
            )
            .join(''))
      );
    };
    animateLabel();
  }, [language, languageStore]);

  const handleSignInObj = (e) => {
    let inputValue = e.target.value;
    let inputName = e.target.name;
    let copySignInObj = { ...signInObj };
    copySignInObj[inputName] = inputValue;
    setSignInObj(copySignInObj);
  };

  const onLoginSubmit = (e) => {
    e.preventDefault();
    if (!signInObj.email || !signInObj.password) {// {data[language].email[5]}
      return toast.error(
        `${!signInObj.email ? 'email' : 'password'} ${data[language].email[6]}`
      );
    }
    let regexPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&$^*()-_=+.,~|])(?=.{8,})/;
    let regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (signInObj.email.match(regexEmail) === null) {
      return toast.error(`${data[language].email[7]}`);
    }
    if (signInObj.password.match(regexPassword) === null) {
      return toast.error(`${data[language].email[8]}`);
    }

    loginUser(signInObj)
      .then((res) => {
        if (res.status === 215) {
          toast.error(res.data);
        } else {
          setUserToLocalStorage(res.data.user);
          dispatch(saveUser(res.data.user));
          toast.success(`${data[language].email[9]}`);
          navigate('/comments');
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  const showHidePassword = () => {
    const passwordType = document.querySelector('input[name="password"]');
    const btnShowHide = document.getElementById('show-hide-btn');
    if (passwordType.type === 'password') {
      passwordType.type = 'text';
      btnShowHide.style.color = 'red';
      btnShowHide.style.textDecoration = 'none';
    } else {
      passwordType.type = 'password';
      btnShowHide.style.color = '#FF8000';
      btnShowHide.style.textDecoration = 'line-through';
    }
  };

  return (
    <div className="container email-page">
      <div className="email-page-holder">
        <h1 className="fs-5 text-center mb-5">{data[language].email[0]}</h1>
        <form autoComplete="off" onSubmit={onLoginSubmit}>
          <div className="form-control-login mb-4">
            <input
              id="email"
              type="text"
              name="email"
              maxLength="60"
              required
              onChange={(e) => handleSignInObj(e)}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="form-control-login">
            <input
              id="password"
              type="password"
              name="password"
              required
              onChange={(e) => handleSignInObj(e)}
            />
            <label htmlFor="password">Password</label>
            <div className="hide-show-password-holder">
              <button
                className="btn"
                type="button"
                id="show-hide-btn"
                onClick={showHidePassword}
              >
                <FaEye />
              </button>
            </div>
          </div>
          <button type="submit" className="btn btn-send form-control">
            {data[language].email[1]}
          </button>
        </form>
      </div>
      <div className="text-center my-3">
        <Link to="/forgotten-password">{data[language].email[2]}</Link>
      </div>

      <div className="register-content-holder">
        <h3 className="fs-5 m-0 txt-account">{data[language].email[3]}</h3>
        <Link className="btn btn-register ms-3" to="/register-user">
          {data[language].email[4]}
        </Link>
      </div>
      <Link className="btn btn-outline-secondary mt-5" to="/comments/login">
        {data[language].email[5]}
      </Link>
    </div>
  );
};

export default EmailLogin;
