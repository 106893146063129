import React from 'react'
import { Link } from 'react-router-dom';

const English = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>English language</h2>
      <p>o.d. Školarac Kikinda</p>
      <p>
        <Link
          to="https://drive.google.com/file/d/11DES6waTWthLNBD3acufzJ5kESCkKPdY/view?usp=drive_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          English-Certificate
        </Link>
      </p>
    </div>
  );
}

export default English;