import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { data } from '../components/data/Data';
import { useSelector } from 'react-redux';

const ProjectsPage = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
    const items = document.querySelectorAll('.timeline li');
    const isElementInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    const callbackFunc = () => {
      return items.forEach((item) => {
        return isElementInViewport(item)
          ? item.classList.add('in-view')
          : item.classList.remove('in-view');
      });
    };

    window.addEventListener('load', callbackFunc);
    window.addEventListener('resize', callbackFunc);
    window.addEventListener('scroll', callbackFunc);
  }, [language, languageStore]);

  return (
    <div className="projects-page container-fluid">
      <header className="intro">
        <div className="container">
          <h1 className="fs-2 mb-3">{data[language].projects[0]}</h1>
        </div>
      </header>
      <section className="timeline">
        <ul>
          <li className="in-view">
            <div>
              <h2 className="mb-1">Termosolar</h2>
              <p>{data[language].projects[8]}</p>
              <p>{data[language].projects[9]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://korisnickicentar.contrateam.com/aff.php?aff=257"
                >
                  {data[language].projects[10]}
                </Link>
                .
              </p>
              <p>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://youtu.be/njijMH4JkEQ"
                >
                  {data[language].projects[11]}
                </Link>
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K?tab=repositories"
                >
                  {data[language].projects[3]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://djurdjevic.eu.org"
                >
                  Termosolar
                </Link>
              </p>
            </div>
          </li>
          <li className="in-view">
            <div>
              <h2 className="mb-1">Zurea Shop</h2>
              <p>{data[language].projects[12]}</p>
              <p>{data[language].projects[13]}</p>
              <p>{data[language].projects[1]}</p>
              <p>
                Backend:{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://dashboard.render.com"
                >
                  Render.com
                </Link>
              </p>
              <p>
                Frontend:{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  {' '}
                  Netlify.com
                </Link>
              </p>
              <p>{data[language].projects[2]}</p>
              <p>
                Backend :{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/zurea_shop_be"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                Frontend :{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/zurea_shop_fr"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://zurea-shop.netlify.app"
                >
                  Zurea Shop
                </Link>
              </p>
            </div>
          </li>
          <li className="in-view">
            <div>
              <h2 className="mb-1">Social Network</h2>
              <p>{data[language].projects[14]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://freehostia.com"
                >
                  Freehostia.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K?tab=repositories"
                >
                  {data[language].projects[3]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxidesign.eu.org/social_network"
                >
                  Social Network
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Classified Ads</h2>
              <p>{data[language].projects[15]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://freehostia.com"
                >
                  {' '}
                  Freehostia.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/classified_ads_php_mysql"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://webdizajn.eu.org/ads.view.php"
                >
                  Classified Ads
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Academic translations</h2>
              <p>{data[language].projects[16]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://freehostia.com"
                >
                  {' '}
                  Freehostia.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K?tab=repositories"
                >
                  {data[language].projects[3]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://akademskiprevodi-lektura.eu.org"
                >
                  Academic translations
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Food Order App</h2>
              <p>
                {data[language].projects[17]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://firebase.google.com/"
                >
                  Firebase
                </Link>
                .
              </p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  {' '}
                  Netlify.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/classified_ads_php_mysql"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://food-order-app-width-firebase.netlify.app/"
                >
                  Food Order App
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">My Movie</h2>
              <p>
                {data[language].projects[17]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://firebase.google.com/"
                >
                  Firebase
                </Link>
                .
              </p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  {' '}
                  Netlify.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/my-movie-react-app"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://my-movie-react-app-maxi.netlify.app"
                >
                  My Movie
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Form Validation</h2>
              <p>{data[language].projects[18]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  {' '}
                  Netlify.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/form-validation-react"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://form-validation-react-maxi.netlify.app"
                >
                  Form Validation
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Todo App</h2>
              <p>{data[language].projects[18]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  {' '}
                  Netlify.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/todo-app?tab=readme-ov-file"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://todo-maxi.netlify.app"
                >
                  Todo App
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Accounts App</h2>
              <p>{data[language].projects[19]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  Netlify.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/accounts_react"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://accounts-react.netlify.app/"
                >
                  Accounts App
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Expenses App</h2>
              <p>{data[language].projects[20]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  {' '}
                  Netlify.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/expenses-app"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://expenses-app-maxi.netlify.app"
                >
                  Expenses App
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Band App</h2>
              <p>{data[language].projects[19]}</p>
              <p>{data[language].projects[21]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  {' '}
                  Netlify.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/band-react-app"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://band-react-app.netlify.app"
                >
                  Band App
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Bank App</h2>
              <p>{data[language].projects[18]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.netlify.com/"
                >
                  {' '}
                  Netlify.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/bank_app_react"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://bank-maxi-react.netlify.app"
                >
                  Bank App
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Maxi website</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://freehostia.com"
                >
                  {' '}
                  Freehostia.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K?tab=repositories"
                >
                  {data[language].projects[3]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://webdizajnmaxi.eu.org"
                >
                  Maxi website
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Darts Scoreboard</h2>
              <p>{data[language].projects[22]}</p>
              <p>{data[language].projects[23]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/darts-scoreboard"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/darts-scoreboard"
                >
                  Darts Scoreboard
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Vulcanizing Workshop</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://freehostia.com"
                >
                  {' '}
                  Freehostia.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K?tab=repositories"
                >
                  {data[language].projects[3]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://webdesign-serbia.eu.org/vulkanizerska-radnja"
                >
                  Vulcanizing Workshop
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Movie App</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/movie_app"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/movie_app"
                >
                  Movie App
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Monthly Bills</h2>
              <p>{data[language].projects[22]}</p>
              <p>{data[language].projects[23]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/monthly_bills"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/monthly_bills"
                >
                  Monthly Bills
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Weather App</h2>
              <p>{data[language].projects[22]}</p>
              <p>{data[language].projects[24]}</p>
              <p>
                {data[language].projects[27]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://openweathermap.org/api"
                >
                  Openweathermap.org
                </Link>
              </p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/weather-app"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/weather-app"
                >
                  Weather App
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">App for the Bank</h2>
              <p>{data[language].projects[25]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/bank-app"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/bank-app"
                >
                  App for the Bank
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Memory Game</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://freehostia.com"
                >
                  {' '}
                  Freehostia.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K?tab=repositories"
                >
                  {data[language].projects[3]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://webdesign-serbia.eu.org/memory-game"
                >
                  Memory Game
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Game Haven</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/Game-Haven"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/Game-Haven/index.html"
                >
                  Game Haven
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Typing Game</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/typing_game"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/typing_game"
                >
                  Typing Game
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Horoscope</h2>
              <p>{data[language].projects[25]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/horoscope"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/horoscope"
                >
                  Horoscope
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Game X-O</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/xo-game"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}

                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/xo-game"
                >
                  Game X-O
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">World Of Tanks</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[26]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://api.worldoftanks.eu"
                >
                  Worldoftanks.eu
                </Link>
              </p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/worldoftanks-app"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/worldoftanks-app"
                >
                  World Of Tanks
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Random API</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/random-api"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/random-api/index.html"
                >
                  Random API
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Joke Generator</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[27]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://api.chucknorris.io/jokes/random"
                >
                  Chucknorris.io
                </Link>
              </p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/random-api-generator"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/random-api-generator"
                >
                  Joke Generator
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Insect Catch Game</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/insect-catch-game"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/insect-catch-game"
                >
                  Insect Catch Game
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Drag and drop Game</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/drag-and-drop-game"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/drag-and-drop-game"
                >
                  Insect Catch Game
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Winner Picker</h2>
              <p>{data[language].projects[22]}.</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/winner_picker"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/winner_picker"
                >
                  Winner Picker
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Password Generator</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/password-generator"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/password-generator"
                >
                  Password Generator
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Calendar</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  {' '}
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/calendar"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/calendar"
                >
                  Calendar
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Quiz</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/quiz"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/quiz"
                >
                  Quiz
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Test your might</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/test-your-might-quiz"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/test-your-might-quiz"
                >
                  Test your might
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Phonebook</h2>
              <p>
                {data[language].projects[22]} {data[language].projects[28]}
              </p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/phonebook"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/phonebook"
                >
                  Phonebook
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Simple manager</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/simple-project-manager"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/simple-project-manager"
                >
                  Simple manager
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Search Users</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[27]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://randomuser.me/api"
                >
                  Randomuser.me
                </Link>
              </p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/search-users"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/search-users"
                >
                  Search Users
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Ivana Kekez CV</h2>
              <p>{data[language].projects[29]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/ivana-cv"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://ivanakekez-portfolio.eu.org"
                >
                  Ivana Kekez CV
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Upload progress bar</h2>
              <p>{data[language].projects[22]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/upload-progress-bar"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/upload-progress-bar"
                >
                  Upload progress bar
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Sluzbena Legitimacija</h2>
              <p>{data[language].projects[30]}</p>
              <p>{data[language].projects[31]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/sluzbena-legitimacija"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://maxi69k.github.io/sluzbena-legitimacija"
                >
                  Sluzbena Legitimacija
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Agency Summer</h2>
              <p>{data[language].projects[32]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com"
                >
                  Github.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/agency-summer-tailwind"
                >
                  {data[language].projects[4]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://webdesign-serbia.eu.org/agency-summer"
                >
                  Agency Summer
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Pravosudna Straža</h2>
              <p>{data[language].projects[30]}</p>
              <p>{data[language].projects[33]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://freehostia.com"
                >
                  Freehostia.com
                </Link>
                .
              </p>
              <p>
                {data[language].projects[2]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K?tab=repositories"
                >
                  {data[language].projects[3]}
                </Link>
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://pravosudnastraza.eu.org/index.html"
                >
                  Pravosudna Straža
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Advokatska Kancelarija</h2>
              <p>{data[language].projects[34]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://korisnickicentar.contrateam.com/aff.php?aff=257"
                >
                  Contra Team-u
                </Link>
                .
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://advokatmaricevic.rs"
                >
                  Advokatska Kancelarija
                </Link>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2 className="mb-1">Bočarska Bašta</h2>
              <p>{data[language].projects[34]}</p>
              <p>
                {data[language].projects[1]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://korisnickicentar.contrateam.com/aff.php?aff=257"
                >
                  Contra Team-u
                </Link>
                .
              </p>
              <p>
                {data[language].projects[7]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://cvecara.in.rs"
                >
                  Bočarska Bašta
                </Link>
              </p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ProjectsPage;
