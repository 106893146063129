import React from 'react';
import Modal from '../components/modal/Modal';
import { Link, useParams } from 'react-router-dom';
import ReactJS from '../components/skills/ReactJS';
import Redux from '../components/skills/Redux';
import JavaScript from '../components/skills/JavaScript';
import Ajax from '../components/skills/Ajax';
import Node from '../components/skills/Node';
import Php from '../components/skills/Php';
import Mysql from '../components/skills/Mysql';
import MongoDb from '../components/skills/MongoDb';
import Tailwind from '../components/skills/Tailwind';
import Sass from '../components/skills/Sass';
import Bootstrap from '../components/skills/Bootstrap';
import Css from '../components/skills/Css';
import Html from '../components/skills/Html';
import Git from '../components/skills/Git';
import Flexbox from '../components/skills/Flexbox';
import ContactForm from '../components/skills/ContactForm';
import Responsive from '../components/skills/Responsive';
import Seo from '../components/skills/Seo';
import WordPress from '../components/skills/WordPress';
import Affinity from '../components/skills/Affinity';
import Hosting from '../components/skills/Hosting';
import English from '../components/skills/English';

const CertificatePage = () => {
  const params = useParams();

  const viewCertificate = () => {
    switch (params.skill) {
      case 'react':
        return <ReactJS />;
      case 'redux':
        return <Redux />;
      case 'javascript':
        return <JavaScript />;
      case 'ajax':
        return <Ajax />;
      case 'node':
        return <Node />;
      case 'php':
        return <Php />;
      case 'mysql':
        return <Mysql />;
      case 'mongo':
        return <MongoDb />;
      case 'tailwind':
        return <Tailwind />;
      case 'sass':
        return <Sass />;
      case 'bootstrap':
        return <Bootstrap />;
      case 'css':
        return <Css />;
      case 'html':
        return <Html />;
      case 'git':
        return <Git />;
      case 'flexbox':
        return <Flexbox />;
      case 'contact-form':
        return <ContactForm />;
      case 'responsive':
        return <Responsive />;
      case 'seo':
        return <Seo />;
      case 'wordpress':
        return <WordPress />;
      case 'affinity':
        return <Affinity />;
      case 'hosting':
        return <Hosting />;
      case 'english':
        return <English />;
      default:
        return;
    }
  };

  return (
    <Modal>
      <div>
        {viewCertificate()}
        <Link
          className="btn btn-outline-secondary form-control mt-3"
          to="/skills"
        >
          Nazad
        </Link>
      </div>
    </Modal>
  );
};

export default CertificatePage;
