import React from 'react';
import { Link } from 'react-router-dom';

const Flexbox = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>Flexbox</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/43651/css-flexbox/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          CSS Flexbox
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>Predavač Nikola Dotlić</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/css-flexbox"
          target="_blank"
          rel="noopener noreferrer"
        >
          CSS Flex
        </Link>
      </p>
      <p>Milan Tair</p>
      <p>
        <Link
          to="https://www.youtube.com/watch?v=HjOeA5heVpg&list=PL-UTrxF0y8kKGrBg1OaBhoP8PWdJqR5Qg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Osnove HTML, CSS i JavaScript - 100 lekcija
        </Link>
      </p>
    </div>
  );
};

export default Flexbox;
