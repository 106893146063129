import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: null,
};

const languageSlicer = createSlice({
  name: 'language',
  // exported reducer
  initialState: initialState,
  // define all actions
  reducers: {
    saveLanguage(state, data) {
      // console.log('data from messageSlicer-->', data.payload);
      state.language = data.payload;
    },
  },
});

export const { saveLanguage } = languageSlicer.actions;
export default languageSlicer.reducer;
