import React from 'react'
import { Link } from 'react-router-dom';

const Php = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>PHP</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/40211/php-1/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          PHP
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/php"
          target="_blank"
          rel="noopener noreferrer"
        >
          PHP - Semestar 1
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/php-s2"
          target="_blank"
          rel="noopener noreferrer"
        >
          PHP - Semestar 2
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/php-semestar-3"
          target="_blank"
          rel="noopener noreferrer"
        >
          PHP - Semestar 3
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/levelup-fullstack"
          target="_blank"
          rel="noopener noreferrer"
        >
          LevelUp FullStack
        </Link>
      </p>
    </div>
  );
}

export default Php;