import React, { useEffect, useState } from 'react';
import Modal from '../components/modal/Modal';
import { Link } from 'react-router-dom';
import { data } from '../components/data/Data';
import { useSelector } from 'react-redux';

const LoginPage = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  return (
    <Modal>
      <div>
        <h1 className="fs-3 mb-4">{data[language].login[0]}</h1>
        <Link
          className="d-block btn btn-outline-primary my-3"
          to="/google-login"
        >
          {data[language].login[1]}
        </Link>
        <Link className="d-block btn btn-outline-light my-3" to="/email-login">
          {data[language].login[2]}
        </Link>
        <Link
          className="btn btn-outline-secondary form-control mt-3"
          to="/comments"
        >
          {data[language].login[3]}
        </Link>
      </div>
    </Modal>
  );
}

export default LoginPage;