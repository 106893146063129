import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { passwordResetRequest } from '../../services/user.service';
import { data } from '../data/Data.js';
import { useSelector } from 'react-redux';

const ForgottenPassword = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  const handleEmail = (e) => {
    let inputEmail = e.target.value.trim();
    let copyEmail = { ...email };
    copyEmail = inputEmail;
    setEmail(copyEmail);
  };

  const onForgottenPassword = (e) => {
    e.preventDefault();
    const regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!email.trim()) {
      return toast.error(`${data[language].forgot_password[6]}`);
    }
    if (!email.match(regexEmail)) {
      return toast.error(`${data[language].forgot_password[7]}`);
    }

    passwordResetRequest({ email, language })
      .then((res) => {
        toast.success(res.data.message);
        navigate('/email-login');
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  return (
    <div className="container forgotten-password-page mt-5">
      <h1 className="fs-3 text-center">{data[language].forgot_password[0]}</h1>
      <div className="col-12 col-sm-8 col-md-6 col-xl-4 m-auto mt-5">
        <form autoComplete="off" onSubmit={onForgottenPassword}>
          <div className="my-3 forgotten-password-page-form">
            <label htmlFor="user-email" className="form-label">
              {data[language].forgot_password[1]}
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="user-email"
              placeholder={data[language].forgot_password[2]}
              aria-describedby="user-email"
              onChange={(e) => handleEmail(e)}
            />
            <div id="emailHelp" className="form-text text-light">
              {data[language].forgot_password[3]}
            </div>
          </div>
          <button type="submit" className="btn btn-send">
            {data[language].forgot_password[4]}
          </button>
        </form>
        <Link className="btn btn-outline-secondary mt-5" to="/email-login">
          {data[language].forgot_password[5]}
        </Link>
      </div>
    </div>
  );
};

export default ForgottenPassword;
