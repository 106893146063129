import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cv_logo from '../assets/img/cv_logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { data } from '../components/data/Data';
import { setLanguageToLocalStorage } from '../services/user.service';
import { saveLanguage } from '../redux/language.slicer';
import playClick from '../assets/mp3/mixkit-select-click-1109.wav';

const AboutMePage = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const audioRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  // Change Language
  const changeLanguage = (e) => {
    if (e.target.getAttribute('class') === 'srb') {
      e.target.setAttribute('class', 'en');
      setLanguageToLocalStorage('en');
      dispatch(saveLanguage('en'));
      setLanguage('en');
    } else {
      e.target.setAttribute('class', 'srb');
      setLanguageToLocalStorage('srb');
      dispatch(saveLanguage('srb'));
      setLanguage('srb');
    }
  };

  return (
    <div className="about-me" id="About_Me">
      <audio ref={audioRef} src={playClick} style={{ display: 'none' }} />
      <div id="language">
        <Link
          onClick={(e) => {changeLanguage(e); audioRef.current.play();}}
          className={data[language].home[5]}
        ></Link>
      </div>
      <div className="column-about">
        <h1 className="fs-3 text-center ">{data[language].about[0]}</h1>
        <p>{data[language].about[1]}.</p>
        <p>{data[language].about[2]}</p>
        <p>{data[language].about[3]}</p>
        <p>
          {data[language].about[4]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://www.youtube.com/@DaniloVesovic"
          >
            {data[language].about[5]}
          </Link>
          {data[language].about[6]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://www.krojacevaskola.com"
          >
            {data[language].about[7]}
          </Link>
          {data[language].about[8]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://www.krojacevaskola.com/ucenik/maxi"
          >
            {data[language].about[9]}
          </Link>
        </p>
        <p>
          {data[language].about[10]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://www.youtube.com/@MilanTair"
          >
            {data[language].about[11]}
          </Link>
          {data[language].about[12]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://www.danilovesovic.com"
          >
            {data[language].about[13]}
          </Link>
          {data[language].about[14]}
          <Link target="_blank" rel="noopener noreferrer" to="/skills">
            {data[language].about[15]}
          </Link>
          {data[language].about[16]}
        </p>
        <p>
          {data[language].about[17]}
          <Link target="_blank" rel="noopener noreferrer" to="/comments">
            {data[language].about[18]}
          </Link>
          {data[language].about[19]}
          <Link target="_blank" rel="noopener noreferrer" to="/register-user">
            {data[language].about[20]}
          </Link>
          {data[language].about[21]}
          <Link target="_blank" rel="noopener noreferrer" to="/email-login">
            {data[language].about[22]}
          </Link>
          {data[language].about[23]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="/forgotten-password"
          >
            {data[language].about[24]}
          </Link>
          {data[language].about[25]}
        </p>
        <p>
          {data[language].about[26]}
          <Link target="_blank" rel="noopener noreferrer" to="/projects">
            {data[language].about[27]}
          </Link>
          {data[language].about[28]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://github.com/Maxi69K"
          >
            {data[language].about[29]}
          </Link>
          {data[language].about[30]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://github.com/Maxi69K"
          >
            {data[language].about[31]}
          </Link>
          {data[language].about[32]}
        </p>
        <p>
          {data[language].about[33]}
          <Link target="_blank" rel="noopener noreferrer" to="/comments">
            {data[language].about[34]}
          </Link>
          {data[language].about[35]}
        </p>
        <p>{data[language].about[36]}</p>
        <p>
          {data[language].about[37]}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="/curriculum-vitae"
          >
            {data[language].about[38]}
          </Link>
          {data[language].about[39]}
        </p>
      </div>
      <div id="bubbles">
        <ul className="circles">
          <li />
          <li />
          <li />
          <li />
          <li />
          <li>
            <div className="column-about-img-holder">
              <Link
                to="/curriculum-vitae"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => audioRef.current.play()}
              >
                <img className="img-fluid" src={cv_logo} alt="CV" />
              </Link>
            </div>
          </li>
          <li />
          <li />
          <li />
          <li />
        </ul>
      </div>
    </div>
  );
};

export default AboutMePage;
