import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { removeUserFromLocalStorage } from '../services/auth.service';
import { removeUser } from '../redux/user.slicer';
import userAvatar from '../assets/img/avatar.webp';
import { updateUserAccount } from '../services/user.service';
import { toggleModal } from '../redux/modal.slicer';
import { data } from '../components/data/Data';

const UserAccountPage = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [user, setUser] = useState({});
  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.hasOwnProperty('maxi_portfolio_user')) {
      return navigate('/comments');
    } else {
      setUser(JSON.parse(localStorage.getItem('maxi_portfolio_user')));
    }
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [navigate, language, languageStore]);

  const handleUpdateUserObj = (e) => {
    let inputValue = e.target.value.trim();
    let inputName = e.target.name;
    let copyNewUser = { ...newUser };
    copyNewUser[inputName] = inputValue;
    setNewUser(copyNewUser);
  };

  const onUpdateUserSubmit = (e) => {
    e.preventDefault();
    let regexPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&$^*()-_=+.,~|])(?=.{8,})/;
    // Validation
    if (
      (newUser.first_name === '') &
      (newUser.last_name === '') &
      (newUser.password === '')
    ) {
      return toast.error(`${data[language].user_acc[15]}`);
    }
    if (newUser.first_name !== '' || newUser.first_name === user.first_name) {
      if (newUser.first_name.length <= 2 || newUser.first_name.length > 15) {
        return toast.error(`${data[language].user_acc[16]}`);
      }
      if (newUser.first_name === user.first_name) {
        newUser.first_name = '';
        return toast.error(`${data[language].user_acc[17]}`);
      }
    }
    if (newUser.last_name !== '' || newUser.last_name === user.last_name) {
      if (newUser.last_name.length <= 2 || newUser.last_name.length > 15) {
        return toast.error(`${data[language].user_acc[18]}`);
      }
      if (newUser.last_name === user.last_name) {
        newUser.last_name = '';
        return toast.error(`${data[language].user_acc[19]}`);
      }
    }
    if (newUser.password !== '') {
      if (newUser.password.match(regexPassword) === null) {
        return toast.error(`${data[language].user_acc[20]}`);
      }
      if (newUser.password !== newUser.password_repeat) {
        return toast.error(`${data[language].user_acc[21]}`);
      }
    }
    //Add email
    newUser.email = user.email;
    // Remove password_repeat
    let copyNewUser = { ...newUser };
    delete copyNewUser.password_repeat;
    updateUserAccount(copyNewUser)
      .then((res) => {
        toast.success(res.data.message);
        setTimeout(() => {
          removeUserFromLocalStorage();
          dispatch(removeUser());
          navigate('/email-login');
        }, 2000);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  // Logout button
  const logout = () => {
    removeUserFromLocalStorage();
    dispatch(removeUser());
    toast.success(`${data[language].user_acc[22]}`);
    navigate('/comments');
  };

  const renderDeleteAccBtn = () => {
    return (
      <Link
        to="/user-account/delete-account"
        className="btn btn-outline-danger"
        onClick={() => dispatch(toggleModal(true))}
      >
        {data[language].user_acc[3]}
      </Link>
    );
  };

  const renderGoogleUser = () => {
    return (
      <div className="user-holder text-center">
        <h2 className="fs-5 mb-3 text-secondary">
          {data[language].user_acc[4]}
        </h2>
        <h3 className="fs-5">{`${user.first_name} ${user.last_name}`}</h3>
        <h3 className="fs-5">{user.email}</h3>
      </div>
    );
  };

  const renderEmailUser = () => {
    return (
      <div className="user-holder text-center">
        <h2 className="fs-5 mb-3 text-secondary">
          {data[language].user_acc[5]}
        </h2>
        <h3 className="fs-5">{`${user.first_name} ${user.last_name}`}</h3>
        <h3 className="fs-5">{user.email}</h3>
        <form autoComplete="off" onSubmit={onUpdateUserSubmit}>
          <div className="mb-3">
            <input
              type="hidden"
              className="form-control"
              name="email"
              defaultValue={user.email || ''}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="first_name" className="form-label">
              {data[language].user_acc[6]}
            </label>
            <input
              placeholder={data[language].user_acc[7]}
              type="text"
              className="form-control"
              id="first_name"
              name="first_name"
              defaultValue={user.first_name || ''}
              onChange={(e) => handleUpdateUserObj(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="last_name" className="form-label">
              {data[language].user_acc[8]}
            </label>
            <input
              placeholder={data[language].user_acc[9]}
              type="text"
              className="form-control"
              id="last_name"
              name="last_name"
              defaultValue={user.last_name || ''}
              onChange={(e) => handleUpdateUserObj(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              {data[language].user_acc[10]}
            </label>
            <input
              placeholder={data[language].user_acc[11]}
              type="password"
              className="form-control"
              id="password"
              name="password"
              onChange={(e) => handleUpdateUserObj(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password-repeat" className="form-label">
              {data[language].user_acc[12]}
            </label>
            <input
              placeholder={data[language].user_acc[13]}
              type="password"
              className="form-control"
              id="password-repeat"
              name="password_repeat"
              onChange={(e) => handleUpdateUserObj(e)}
            />
          </div>
          <button type="submit" className="btn btn-send form-control ">
            {data[language].user_acc[14]}
          </button>
        </form>
      </div>
    );
  };

  return (
    <div className="container mt-5 user-account-page">
      <h1 className="fs-3 text-center mb-3">{data[language].user_acc[0]}</h1>
      <div className="text-center account-img-holder mb-3">
        <img
          src={userAvatar}
          alt="User"
          className="img-fluid img-thumbnail rounded"
        />
      </div>
      {user._id ? renderEmailUser() : renderGoogleUser()}
      <div className="d-flex btn-holder justify-content-between align-items-center gap-3 mt-3">
        <button
          onClick={() => logout()}
          className="btn btn-outline-secondary log-out-comment"
        >
          {data[language].user_acc[1]}
        </button>
        {user._id ? renderDeleteAccBtn() : null}
        <Link className="btn btn-outline-secondary" to="/comments">
          {data[language].user_acc[2]}
        </Link>
      </div>
      <Outlet />
    </div>
  );
};

export default UserAccountPage;
