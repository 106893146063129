import React from 'react';
import Top from './Top';
import Navigation from './Navigation';

const Nav = () => {
  return (
    <div className="main-header">
      <header>
        <Top />
        <Navigation />
      </header>
    </div>
  );
};

export default Nav;
