import React from 'react'
import { Link } from 'react-router-dom';

const WordPress = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>WordPress</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/36800/wordpress-instalacija/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          WordPress - instalacija
        </Link>
      </p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/36801/rad-sa-gotovom-temom/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rad sa gotovom temom
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>Predavač Rade Mrkić</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/wordpress-semestar-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wordpress Semestar 1
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/wordpress-semestar-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wordpress Semestar 2
        </Link>
      </p>
    </div>
  );
}

export default WordPress;