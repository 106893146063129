import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { data } from '../data/Data.js';
import { useSelector } from 'react-redux';
import playClick from '../../assets/mp3/mixkit-select-click-1109.wav';

const Navigation = ({ isMenuOpen, changeHamburgerMenu }) => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [menuDisplay, setMenuDisplay] = useState('none');
  const audioRef = useRef(null);

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  useEffect(() => {
    // Ažurira prikaz menija u skladu sa promenom statusa isMenuOpen
    setMenuDisplay(isMenuOpen ? 'block' : 'none');
  }, [isMenuOpen]);

  const handleCloseMenu = () => {
    if (isMenuOpen) {
      changeHamburgerMenu();
    }
  };

  return (
    <nav className="main-nav" style={{ display: menuDisplay }}>
      <audio ref={audioRef} src={playClick} style={{ display: 'none' }} />
      <ul className="main-menu list-reset">
        <li>
          <Link
            to="/"
            id="home"
            onClick={() => {
              handleCloseMenu();
              audioRef.current.play();
            }}
          >
            {data[language].navigation[0]}
          </Link>
        </li>
        <li>
          <Link
            to="/about-me"
            id="about-me"
            onClick={() => {
              handleCloseMenu();
              audioRef.current.play();
            }}
          >
            {data[language].navigation[1]}
          </Link>
        </li>
        <li>
          <Link
            to="/skills"
            id="skills"
            onClick={() => {
              handleCloseMenu();
              audioRef.current.play();
            }}
          >
            {data[language].navigation[2]}
          </Link>
        </li>
        <li>
          <Link
            to="/projects"
            id="projects"
            onClick={() => {
              handleCloseMenu();
              audioRef.current.play();
            }}
          >
            {data[language].navigation[3]}
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            id="contact"
            onClick={() => {
              handleCloseMenu();
              audioRef.current.play();
            }}
          >
            {data[language].navigation[4]}
          </Link>
        </li>
        <li>
          <Link
            to="/comments"
            id="comments"
            onClick={() => {
              handleCloseMenu();
              audioRef.current.play();
            }}
          >
            {data[language].navigation[5]}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
