import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className="error-page">
      <div className="planet">
        <div className="inner">
          <div className="surface"></div>
        </div>
        <div className="ring"></div>
        <div className="moon"></div>
        <span className="error-span">4</span>
        <span className="error-span">4</span>
        <div className="stars s1"></div>
        <div className="stars s2"></div>
        <div className="stars s3"></div>
        <div className="stars s4"></div>
      </div>
        <p className="alt">404</p>
        <Link to='/' className="info">Back to HomePage</Link>
    </div>
  );
}

export default ErrorPage;