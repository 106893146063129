import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { data } from '../data/Data.js';

const Footer = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  return (
    <footer className="my-footer">
      <small>
        &copy; 2024 <Link to="/">Portfolio</Link> by
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to="https://webdevelopermaxi.eu.org"
        >
          <span> Maxi </span>
        </Link>
        .{' '}
        <Link
          to="/terms-and-conditions"
          // target="_blank"
          // rel="noopener noreferrer"
          // to="https://www.termsfeed.com/live/d0d806d0-99a1-44aa-9336-9508d608406a"
        >
          {data[language].footer[0]}
        </Link>
      </small>
    </footer>
  );
};

export default Footer;
