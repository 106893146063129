import React from 'react'
import { Link } from 'react-router-dom';

const ReactJS = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>React JS</h2>
      <p>Danilo Vešović</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/react-semestar-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          React - Semestar 1
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/react-semestar-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          React - Semestar 2
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/reactjs-semestar-3"
          target="_blank"
          rel="noopener noreferrer"
        >
          React - Semestar 3
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/levelup-fullstack"
          target="_blank"
          rel="noopener noreferrer"
        >
          LevelUp FullStack
        </Link>
      </p>
      <p>Milan Tair</p>
      <p>
        <Link
          to="https://www.youtube.com/watch?v=xiUIQRkYYuI&list=PL-UTrxF0y8kKkQ7YPWPjYAAentBEbGA9g"
          target="_blank"
          rel="noopener noreferrer"
        >
          Razvoj veb aplikacija - 100 lekcija
        </Link>
      </p>
      <p>Maximilian Schwarzmüller</p>
      <p>
        <Link
          to="https://www.udemy.com/course/react-the-complete-guide-incl-redux"
          target="_blank"
          rel="noopener noreferrer"
        >
          React - The Complete Guide (incl. React Router & Redux)
        </Link>
      </p>
    </div>
  );
}

export default ReactJS;