import React from 'react'
import { Link } from 'react-router-dom';

const Sass = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>SASS</h2>
      <p>Danilo Vešović</p>
      <p>Predavač Nikola Dotlić</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/sass-preprocesor"
          target="_blank"
          rel="noopener noreferrer"
        >
          SASS
        </Link>
      </p>
    </div>
  );
}

export default Sass;
