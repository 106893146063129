import React from 'react'
import { Link } from 'react-router-dom';

const Responsive = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>Responsive Design</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/43750/responsive-dizajn-i-bootstrap-3/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Responsive dizajn
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>Predavač Nikola Dotlić</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/css-responsive"
          target="_blank"
          rel="noopener noreferrer"
        >
          Responsive design
        </Link>
      </p>
    </div>
  );
}

export default Responsive;