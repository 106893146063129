import React from 'react'
import { Link } from 'react-router-dom';

const Hosting = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>Hosting Setup</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/36799/domen-hosting-cpanel/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Domen, hosting, cPanel
        </Link>
      </p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/36802/lokalni-server-xampp/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Lokalni server - XAMPP
        </Link>
      </p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/36806/atom-text-editor/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Atom text-editor
        </Link>
      </p>
    </div>
  );
}

export default Hosting;