import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { activateAccount } from '../services/user.service';
import { toast } from 'react-toastify';
import { data } from '../components/data/Data';
import { useSelector } from 'react-redux';

const ActivatePage = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [message, setMessage] = useState('');
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  useEffect(() => {
    activateAccount(userId)
      .then((res) => {
        setMessage(
          `Account successfully activated. Redirecting to login page...`
        );
        toast.success(res.data.message);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => {
        setTimeout(() => {
          navigate('/email-login');
        }, 5000);
      });
  }, [navigate, userId]);

  return (
    <div className="d-flex justify-content-center align-content-center flex-column text-center p-3">
      <h1>{data[language].activate_acc[0]}</h1>
      <p className="text-success">{message}</p>
    </div>
  );
};

export default ActivatePage;
