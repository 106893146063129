import React from 'react'
import { Link } from 'react-router-dom';

const Git = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>Git & Github</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/44713/git-i-github/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Git i GitHub
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/levelup-fullstack"
          target="_blank"
          rel="noopener noreferrer"
        >
          LevelUp FullStack
        </Link>
      </p>
      <p>kurseviprogramiranja.rs</p>
      <p>
        <Link
          to="https://drive.google.com/file/d/1HwFXmiZ3w-Dq5Z5r1z1G9varD7SXcFlP/view?usp=drive_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Uvod u Git i GitHub
        </Link>
      </p>
    </div>
  );
}

export default Git;