import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { sendContactMail } from '../../services/mail.service';
import { data } from '../data/Data.js';

const Verification = () => {
  const messageStore = useSelector((state) => state.messageStore.message); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [inputData, setInputData] = useState({});
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const navigate = useNavigate();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  const memoizedCallback = useCallback(() => {
    const checkbox = document.getElementById('checkbox');
    const submitBtn = document.getElementById('submitBtn');
    const elements = document.querySelectorAll('.element');
    const selectColor = document.getElementById('selectColor');
    if (checkbox) {
      checkbox.disabled = true;
    }
    if (submitBtn) {
      submitBtn.disabled = true;
    }

    elements.forEach(function (element) {
      element.addEventListener('click', function () {
        if (element.innerHTML === selectColor.innerHTML) {
          checkbox.checked = true;
          alert('You are human!');
          submitBtn.disabled = false;
          submitBtn.classList.remove('btn-light');
          submitBtn.classList.add('btn-success');
          toast.success('Press "Submit" button!');
        } else {
          alert('Please verify that you are human!');
          navigate('/contact');
        }
      });
    });

    function getRandomColor() {
      const letter = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letter[Math.floor(Math.random() * letter.length)];
      }
      return color;
    }

    function randomize() {
      const selectColor = document.getElementById('selectColor');
      const elements = document.querySelectorAll('.element');
      let randSelectColor = [];
      let r = Math.floor(Math.random() * 9);
      elements.forEach(function (element) {
        const color = getRandomColor();
        randSelectColor.push(color);
        element.style.backgroundColor = color;
        element.innerHTML = color;
      });
      if (selectColor) {
        selectColor.innerHTML = randSelectColor[r];
      }
    }
    randomize();
  }, [navigate]);

  useEffect(() => {
    if (!messageStore) {
      return navigate('/contact');
    }
    setInputData(messageStore);
    memoizedCallback();
  }, [memoizedCallback, messageStore, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    const submitBtn = document.getElementById('submitBtn');
    submitBtn.disabled = true;

    sendContactMail({inputData, language})
      .then((res) => {
        toast.success(`${data[language].verification[3]}`);
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => {
        setTimeout(() => {
          navigate('/');
        }, 6000);
      });
  };

  const verificationForm = () => {
    return (
      <div className="verification-page">
        <ToastContainer position="bottom-center" theme="dark" />
        <div className="form-container">
          <form>
            <h1 className="fs-4">
              {data[language].verification[4]} <span id="selectColor"></span>{' '}
              {data[language].verification[5]}
            </h1>
            <input type="checkbox" id="checkbox" />
            <label htmlFor="checkbox">{data[language].verification[6]}</label>
            <button
              onClick={(e) => submitHandler(e)}
              className="btn btn-light"
              type="submit"
              id="submitBtn"
            >
              {data[language].verification[7]}
            </button>
          </form>
        </div>

        <div className="element-holder">
          <div className="element"></div>
          <div className="element"></div>
          <div className="element"></div>
          <div className="element"></div>
          <div className="element"></div>
          <div className="element"></div>
          <div className="element"></div>
          <div className="element"></div>
          <div className="element"></div>
        </div>
      </div>
    );
  };

  return verificationForm();
};

export default Verification;
