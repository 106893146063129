import React from 'react'
import ContactForm from '../components/contact/ContactForm';

const ContactPage = () => {
  return (
    <div className='container-fluid contact-page'>
        <ContactForm />
    </div>
  )
}

export default ContactPage;