import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { data } from '../components/data/Data';
import Astronaut from '../assets/img/astronaut.png';
import { setLanguageToLocalStorage } from '../services/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { saveLanguage } from '../redux/language.slicer';
import playClick from '../assets/mp3/mixkit-select-click-1109.wav';

const HomePage = () => {
  const languageStore = useSelector((state) => state.languageStore.language);
  const [user, setUser] = useState({});
  const [language, setLanguage] = useState('en');
  const [clicked, setClicked] = useState(false);
  const [moonClass, setMoonClass] = useState('coming');
  const dispatch = useDispatch();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_user')) {
      setUser(JSON.parse(localStorage.getItem('maxi_portfolio_user')));
    }
  }, [language, languageStore]);

  function greetingWithName(name) {
    const lastLetter = name.charAt(name.length - 1).toLowerCase();
    let fixedName;
    switch (lastLetter) {
      case 'n':
        fixedName = `${name}e`;
        break;
      case 'š':
        fixedName = `${name}e`;
        break;
      case 'r':
        if (name.endsWith('ar')) {
          fixedName = `${name.slice(0, -2)}re`;
        } else {
          fixedName = `${name.slice(0, -1)}e`;
        }
        break;
      default:
        fixedName = `${name}`;
    }
    return fixedName;
  }

  const audioRef = useRef(null);
  const changeMoon = (e) => {
    if (clicked) {
      return;
    }
    setClicked(true);
    setMoonClass('outgoing');
    audioRef.current.play();

    const messageBox = document.getElementById('welcome-message');
    const getTimes = () => {
      let date = new Date();
      let h = date.getHours();
      h = h < 10 ? (h = '0' + h) : h;
      let timeOfDay = null;
      if (h < 10) {
        timeOfDay =
          user && user.first_name
            ? `${data[language].home[0]} ${greetingWithName(user.first_name)}`
            : `${data[language].home[0]}`;
      }
      if (h >= 10) {
        timeOfDay =
          user && user.first_name
            ? `${data[language].home[1]} ${greetingWithName(user.first_name)}`
            : `${data[language].home[1]}`;
      }
      if (h >= 19) {
        timeOfDay =
          user && user.first_name
            ? `${data[language].home[2]} ${greetingWithName(user.first_name)}`
            : `${data[language].home[2]}`;
      }
      return timeOfDay;
    };

    const myText = `${getTimes()} ${data[language].home[3]}`;

    let textArray = myText.split('');

    const writeAGreetingMessage = () => {
      setTimeout(() => {
        let intervalText = setInterval(() => {
          let letter = textArray.shift();
          if (textArray.length > 0) {
            messageBox.innerHTML += letter;
          } else {
            clearInterval(intervalText);
          }
        }, 30);
      }, 1000);
    };

    const createBtn = () => {
      const aLink = document.createElement('a');
      aLink.classList.add('a-link');
      aLink.href = '/about-me';
      messageBox.appendChild(aLink);
    };

    const readMoreButton = () => {
      setTimeout(() => {
        createBtn();
        const myALink = document.querySelector('#welcome-message > .a-link');
        if (myALink) {
          myALink.innerText = `${data[language].home[4]}`;
        }
      }, 10000);
    };

    const moveAstronaut = () => {
      const astronaut = document.querySelector('#astronaut > img');
      setTimeout(() => {
        astronaut.style.width = '100%';
        astronaut.style.transform = 'rotate(360deg)';
      }, 5000);
    };
    writeAGreetingMessage();
    readMoreButton();
    moveAstronaut();
  };

  const changeLanguage = (e) => {
    const welcomeMessage = document.getElementById('welcome-message');
    if (e.target.getAttribute('class') === 'srb') {
      e.target.setAttribute('class', 'en');
      setLanguageToLocalStorage('en');
      dispatch(saveLanguage('en'));
      setLanguage('en');
    } else {
      e.target.setAttribute('class', 'srb');
      setLanguageToLocalStorage('srb');
      dispatch(saveLanguage('srb'));
      setLanguage('srb');
    }
    if (welcomeMessage.innerHTML.length > 0) {
      window.location.reload();
    }
  };

  return (
    <div className="page" id="Home">
      <audio ref={audioRef} src={playClick} style={{ display: 'none' }} />
      <div id="language">
        <Link
          onClick={(e) => {changeLanguage(e); audioRef.current.play();}}
          className={data[language].home[5]}
        ></Link>
      </div>
      <div className="column">
        <div id="portfolio-holder">
          <section className="heading">
            <h1>MyPortfolio</h1>
            <div className="span-holder">
              <h3 className="span home-loader">
                <span>G</span>
                <span>O</span>
                <span>R</span>
                <span>A</span>
                <span>N</span>
                <span> </span>
                <span>K</span>
                <span>E</span>
                <span>K</span>
                <span>E</span>
                <span>Z</span>
                <span> </span>
                <span>M</span>
                <span>A</span>
                <span>X</span>
                <span>I</span>
              </h3>
            </div>
          </section>
        </div>
        <div
          onClick={(e) => changeMoon(e)}
          id="moon"
          className={moonClass}
        ></div>
        <div id="welcome-message"></div>
        <div id="astronaut">
          <img src={Astronaut} alt="Astronaut" />
        </div>
      </div>
      <div className="stars s1"></div>
      <div className="stars s2"></div>
      <div className="stars s3"></div>
      <div className="stars s4"></div>
    </div>
  );
};

export default HomePage;
