import React, { useEffect, useState } from 'react';
import Modal from '../components/modal/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleModal } from '../redux/modal.slicer';
import avatar from '../assets/img/avatar.webp';
import { sendImpression } from '../services/user.service';
import { data } from '../components/data/Data';

const WriteImpressionPage = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [user, setUser] = useState({});
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [impression, setImpression] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.hasOwnProperty('maxi_portfolio_user')) {
      return navigate('/comments');
    }
    dispatch(toggleModal(true));
    setUser(JSON.parse(localStorage.getItem('maxi_portfolio_user')));
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [userStore, navigate, dispatch, language, languageStore]);

  const handleImpressionObj = (e) => {
    let inputValue = e.target.value.trim();
    setImpression(inputValue);
  };

  const onImpressionSubmit = (e) => {
    e.preventDefault();
    let { isActive, isAdmin, password, __v, _id, ...newImpression } = user;
    delete newImpression.isActive;
    delete newImpression.isAdmin;
    delete newImpression.password;
    delete newImpression.__v;
    delete newImpression._id;
    newImpression.impression = impression;

    sendImpression(newImpression)
      .then((res) => {
        toast.success(`${data[language].impression[5]}`);
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => {
        navigate('/comments');
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      });
  };

  return (
    <Modal>
      <div className="container impression-page">
        <h1 className="fs-3 mb-4">{data[language].impression[0]}</h1>
        <form autoComplete="off" onSubmit={onImpressionSubmit}>
          <div className="d-flex gap-3 my-3">
            <div className="text-center impression-user-img">
              <img
                className="img-fluid img-thumbnail"
                src={avatar}
                alt="User"
              />
            </div>
            <div>
              <input
                type="text"
                className="form-control impression-input impression-name"
                id="first_name"
                name="first_name"
                value={user.first_name || ''}
                readOnly
              />
              <input
                type="text"
                className="form-control impression-input impression-name"
                id="last_name"
                name="last_name"
                value={user.last_name || ''}
                readOnly
              />
              <input
                type="email"
                className="form-control impression-input"
                id="email"
                name="email"
                value={user.email || ''}
                readOnly
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="impression" className="form-label label-comment">
              {data[language].impression[1]}
            </label>
            <textarea
              className="form-control"
              id="impression"
              placeholder={data[language].impression[2]}
              name="impression"
              rows={10}
              maxLength={700}
              onChange={(e) => handleImpressionObj(e)}
            ></textarea>
          </div>
          <button
            type="submit"
            className="btn btn-outline-secondary form-control"
          >
            {data[language].impression[3]}
          </button>
        </form>
        <Link className="btn btn-outline-secondary mt-4" to="/comments">
          {data[language].impression[4]}
        </Link>
      </div>
    </Modal>
  );
};

export default WriteImpressionPage;
