import React from 'react'
import { Link } from 'react-router-dom';

const Tailwind = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>MongoDB</h2>
      <p>Danilo Vešović</p>
      <p>Predavač Rade Mrkić</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/tailwind-css"
          target="_blank"
          rel="noopener noreferrer"
        >
          TailWind CSS
        </Link>
      </p>
    </div>
  );
}

export default Tailwind;