import { configureStore } from '@reduxjs/toolkit';
import userSlicer from './user.slicer';
import loaderSlicer from './loader.slicer';
import modalSlicer from './modal.slicer';
import messageSlicer from './message.slicer';
import languageSlicer from './language.slicer';

const storeRedux = configureStore({
  reducer: {
    userStore: userSlicer,
    loaderStore: loaderSlicer,
    modalStore: modalSlicer,
    messageStore: messageSlicer,
    languageStore: languageSlicer,
  },
});

export default storeRedux;
