import React from 'react';
import { Link } from 'react-router-dom';

const JavaScript = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>JavaScript</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/38684/javascript-trilogija-komplet/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          JavaScript Trilogija - Komplet
        </Link>
      </p>
      <p>freeCodeCamp</p>
      <p>
        <Link
          to="https://www.freecodecamp.org/certification/Maxi69K/javascript-algorithms-and-data-structures"
          target="_blank"
          rel="noopener noreferrer"
        >
          Javascript algorithms and data structures
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>
        <Link
          to="https://www.youtube.com/@DaniloVesovic"
          target="_blank"
          rel="noopener noreferrer"
        >
          JavaScript YouTube channel
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/levelup-fullstack"
          target="_blank"
          rel="noopener noreferrer"
        >
          LevelUp FullStack
        </Link>
      </p>
      <p>Milan Tair</p>
      <p>
        <Link
          to="https://www.youtube.com/watch?v=HjOeA5heVpg&list=PL-UTrxF0y8kKGrBg1OaBhoP8PWdJqR5Qg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Osnove HTML, CSS i JavaScript - 100 lekcija
        </Link>
      </p>
      <p>Brad Traversy</p>
      <p>
        <Link
          to="https://www.udemy.com/course/50-projects-50-days"
          target="_blank"
          rel="noopener noreferrer"
        >
          50 Projects In 50 Days - HTML, CSS & JavaScript
        </Link>
      </p>
      <p>Norbert B. Menyhart</p>
      <p>
        <Link
          to="https://www.udemy.com/course/30-html-css-javascript-projects-in-30-days-for-beginners"
          target="_blank"
          rel="noopener noreferrer"
        >
          30 HTML CSS & JavaScript projects in 30 Days for Beginners
        </Link>
      </p>
    </div>
  );
};

export default JavaScript;
