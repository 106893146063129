import React from 'react'
import { Link } from 'react-router-dom';

const Affinity = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>Affinity Designer</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/46266/affinity-designer/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Affinity Designer
        </Link>
      </p>
    </div>
  );
}

export default Affinity;