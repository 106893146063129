import React, { useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import avatar from '../../assets/img/avatar.webp';
import { registerUser } from '../../services/auth.service';
import { data } from '../data/Data.js';

const ConfirmRegister = () => {
  const userStore = useSelector((state) => state.userStore.user); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [user, setUser] = useState({});
  const [language, setLanguage] = useState('en');
  const navigate = useNavigate();

  useEffect(() => {
    if (!userStore) {
      return navigate('/register-user');
    }
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
    setUser(userStore);
  }, [userStore, navigate, language, languageStore]);

  const sendRegister = () => {
    registerUser({ user, language })
      .then((res) => {
        toast.success(`${data[language].confirmation[5]}`);
        navigate('/email-login');
      })
      .catch((err) => toast.error(err.response.data));
  };

  return (
    <Modal>
      <div className="confirm-register text-center">
        <h1 className="fs-5 mb-4">{data[language].confirmation[0]}</h1>
        <div className="confirm-register-holder">
          <h2 className="fs-5 m-0 text-secondary">{`${user.first_name} ${user.last_name}`}</h2>
          <div className="my-1 img-holder">
            <img className="img-fluid img-thumbnail" src={avatar} alt="User" />
          </div>
          <h3 className="fs-5 m-0">
            <span className="text-secondary">
              {data[language].confirmation[1]}
            </span>{' '}
            {user.first_name}
          </h3>
          <h3 className="fs-5 m-0">
            <span className="text-secondary">
              {data[language].confirmation[2]}
            </span>{' '}
            {user.last_name}
          </h3>
          <h3 className="fs-5 m-0">
            <span className="text-secondary">E-mail:</span> {user.email}
          </h3>
          <button
            onClick={() => sendRegister()}
            className="btn btn-send form-control my-3"
          >
            {data[language].confirmation[3]}
          </button>
        </div>
        <Link
          className="btn btn-outline-secondary form-control mt-3"
          to="/register-user"
        >
          {data[language].confirmation[4]}
        </Link>
      </div>
    </Modal>
  );
};

export default ConfirmRegister;
