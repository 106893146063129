const data = {
  en: {
    navigation: [`Home`, `About`, `Skills`, `Projects`, `Contact`, `Comments`],
    home: [
      `Good morning`,
      `Good afternoon`,
      `Good evening`,
      `and welcome to my portfolio website. My name is Goran Kekez and my nickname is Maxi. I'm Junior Full Stack Developer. For more information about me, click the "Read more" button.`,
      `Read more`,
      `en`,
    ],
    about: [
      `About me`,
      `Welcome to my portfolio website.`,
      `My name is Goran Kekez, (my) nickname (is) Maxi. I coded this website in order to introduce myself to employers who are in search of web programmers, I will try to present (introduce) myself and my knowledge acquired so far.`,
      `I was born in May 1969, I live in Serbia, city Kikinda. I graduated from high school "Mihajlo Pupin" Kikinda, as a mechanical technician. I have been involved in sports since my teenage days, I have trained Kickboxing for about 36 years and was the champion of the former SFRY and later the Republic of Serbia, I also have a coaching license and was a coach in the same sport. Even today, I am active and keep fit in the gym. I have passed all categories in my driver's license. I have a lot of experience in working with people, since I worked as a traffic policeman and I am currently employed as a judicial guard, I am an employee of the Ministry of Justice of the Republic of Serbia in the Basic Court in Kikinda, where I have been working for 17 years.`,
      `Since 2001, when I bought my first computer, I fell in love with computing. I installed windows operating systems, installed various programs, assembled different hardware into one computer both for friends and for clients who hired me. In 2020, I made my first website using CMS WordPress. I really liked it, but I was curious and wanted to code a similar website myself without a CMS. So I started watching`,
      `Danilo Vešović's `,
      `first tutorials on HTML and CSS via YouTube, then I found out about `,
      `Krojačeva Škola `,
      `where I learned a lot about HTML, CSS and JavaScript and a lot of other things needed for programming. There I also acquired a lot of licenses that I own, and you can see my profile with them `,
      `here. `,
      `Along with them, I watched and followed `,
      `Milan Tairo's `,
      `lectures on YouTube, where a man who has enormous knowledge selflessly shares with his followers. When I learned HTML, CSS, JavaScript, etc... I started learning ReactJS, PHP, NodeJS and a little more advanced things with Danilo Vešović in his `,
      `programming school `,
      `where I repeated what I had already learned and learned new technologies. See the `,
      `skills page `,
      `for details. And clicking on each skill will lead you where I had learned it and the certificate I received.`,
      `I have developed this portfolio website in React, using Redux, Axios, Toastify, SASS, Bootstrap, etc. for the front-end. For the back end, I utilized Node, bcrypts for password encryption, mongoose and MongoDB to store user data and feedback on the `,
      `Comments page. `,
      `To leave feedback, you need to `,
      `register `,
      `first if you haven’t already, then you receive a confirmation e-mail to verify your e-mail, so you can then `,
      `log in. `,
      `In case you forget your password, you can request a `,
      `password reset, `,
      `which will also be sent via e-mail address. If you cannot find the sent e-mail from this site, please check your spam folder, as sometimes they get filtered there. I designed the layout myself and it is original. I ensured that each page was different and unique by adding various animations to elements.`,
      `The `,
      `“My Projects” `,
      `page showcases just a part of projects that I have worked on when practicing, and some commissioned by clients. Clicking on the site link opens a new tab for a live preview. In the project description, I briefly describe which programming languages and technologies I used for development of that project. Some projects provide a code which is linked to my `,
      `GitHub `,
      `account, and some remain private to uphold client confidentiality. As of 03/24, I have 174 projects on my `,
      `GitHub `,
      `account and I will try and expand them further.`,
      `Feel free to `,
      `leave `,
      `a feedback on the site, which will only take a few minutes, but would be greatly appreciated. Thank you in advance.`,
      `I am eager to transition into a career and do what I love the most, and that is coding, further improving acquired knowledge, and learning new programming languages and technologies.`,
      `If you believe that I meet your criteria and would be a positive impact on your team, I would be honored if you clicked on the floating `,
      `CV balloon `,
      `which is going up from the bottom of the “About Me” page. `,
    ],
    skills: [`SKILLS`],
    projects: [
      `My Projects`,
      `Hosting set to `,
      `Code: `,
      `private repo on GitHub`,
      `repo on GitHub`,
      `Backend: `,
      `Frontend: `,
      `Link to the site: `,
      `Full stack Web-Shop, frontend made in React, used Redux, Axios, SCSS, Bootstrap.`,
      `Backend made with NodeJS and SQL connected to MySQL database.`,
      `Contra Team`,
      `YouTube View Admin Panel`,
      `Exercise Full stack project, frontend made in React, used Redux, Axios, SCSS, Bootstrap.`,
      `Backend made with NodeJS and connected to MongoDB database.`,
      `Project made in PHP. Used HTML, Bootstrap and SQL connected to MySQL database. Admin Panel for Administrators.`,
      `Project made in PHP. Used HTML, Bootstrap and SQL connected to MySQL database.`,
      `Project made in React. Bootstrap used. Bilingual site EN and SRB.`,
      `Project made in React. Database linked to `,
      `Project made in React. Data connected to Localstorage.`,
      `Project made in React, Bootstrap was used.`,
      `Project made in React.`,
      `API data is pulled from GitHub.`,
      `Project made in HTML, CSS and vanilla JavaScript.`,
      `Data is stored on Localstorage.`,
      `Settings are saved to Localstorage.`,
      `Project made in HTML, CSS, Bootstrap and vanilla JavaScript.`,
      `Statistics data is pulled from the API `,
      `Data is pulled from the API `,
      `Data connected to Localstorage.`,
      `Project made in HTML, CSS and Bootstrap.`,
      `Project made in HTML and CSS.`,
      `For members of the Ministry of Justice of the Judicial Guard of the Republic of Serbia`,
      `Project made in HTML and TailwindCSS.`,
      `My first work in html and css`,
      `The site was created in WordPress, completely edited with Elementor.`,
    ],
    contact: [
      `Contact`,
      `First name`,
      `John`,
      'Last name',
      `Doe`,
      `johndoe@gmail.com`,
      `Phone number`,
      `Message`,
      `Enter a message here...`,
      `Send`,
      `First name is required!`,
      `Last name is required!`,
      `Email is required!`,
      `Message is required!`,
    ],
    verification: [
      `You are human!`,
      `Press "Submit" button!`,
      `Please verify that you are human!`,
      `Message is sent!`,
      `Select the hexadecimal color:`,
      `for verification!`,
      `Are you Human?`,
      `Submit`,
    ],
    comments: [
      `Impressions Book`,
      `Click on a book to leave a comment.`,
      `Sign in to leave a comment.`,
    ],
    login: [
      `Select a login method:`,
      `Sign in with a Google Account`,
      `Sign up via email address`,
      `Back`,
    ],
    email: [
      `Sign up via e-mail address`,
      `Sign in`,
      `Forgot password?`,
      `No account?`,
      `Registration`,
      `Back`,
      ` field is required!`,
      `Enter valid email address!`,
      `Password must contain: min 8 characters, min 1 special character, min 1 uppercase letter and min 1 lowercase letter!`,
      `Successfully logged in!`,
    ],
    google: [`Back`, `You are successfully logged in!`],
    registration: [
      `Registration via e-mail address`,
      `First name`,
      `John`,
      `Last name`,
      `Doe`,
      `johndoe@gmail.com`,
      `Password`,
      `your password`,
      `Repeat password`,
      `Look at the details`,
      `Back`,
      `Fill in all fields!`,
      `First name must contain min 2 and max 15 characters!`,
      `Last name must contain min 2 and max 15 characters!`,
      `Enter the correct email address!`,
      `Password must contain: min 8 characters, min 1 special character, min 1 uppercase letter and min 1 lowercase letter!`,
      `Repeat the same password!`,
    ],
    confirmation: [
      `Your registration:`,
      `First name:`,
      `Last name`,
      `Send`,
      `Back`,
      `Check email - confirm registration!`,
    ],
    forgot_password: [
      `Request to change your password`,
      `Enter your e-mail address`,
      `johndoe@gmail.com`,
      `After sending, check your e-mail messages.`,
      `Send`,
      `Back`,
      `The field cannot be empty!`,
      `Enter a valid e-mail address!`,
    ],
    reset_password: [
      `Reset user password`,
      `Create new password:`,
      `New password`,
      `enter a new password`,
      `Retry new password`,
      `repeat new password`,
      `Save Changes`,
      `Field can't be empty!`,
      `Password must contain: min 8 characters, min 1 special character, min 1 uppercase letter and min 1 lowercase letter!`,
      `Repeat the same password!`,
    ],
    user_acc: [
      `User account`,
      `Sign out`,
      `Back`,
      `Delete account`,
      `Google account`,
      `Email account`,
      `First name`,
      `enter a new first name`,
      `Last name`,
      `enter a new last name`,
      `Password`,
      `enter a new password`,
      `Repeat password`,
      `repeat new password`,
      `Submit changes`,
      `First make changes!`,
      `First name must contain min 2 and max 15 characters!`,
      `First, change First name!`,
      `Last name must contain min 2 and max 15 characters!`,
      `First, change Last name!`,
      `Password must contain: min 8 characters, min 1 special character, min 1 uppercase letter and min 1 lowercase letter!`,
      `Repeat the same password!`,
      `Successful checkout!`,
    ],
    activate_acc: [
      `Account activation`,
      `Account successfully activated. Redirecting to login page...`,
    ],
    delete_acc: [
      `Delete Account:`,
      `Do you really want to delete your account?`,
      `Delete`,
      `Back`,
    ],
    impression: [
      `Make your impression`,
      `Your comment:`,
      `Enter text up to a maximum of 700 characters...`,
      `Send`,
      `Back`,
      `Comment has been sent!`,
    ],
    cv_page: [
      `Download CV`,
      `Kikinda, Serbia`,
      `Practice`,
      `Projects`,
      `Full-stack Web-Shop with front-end developed in React using Redux, Axios, SCSS and Bootstrap. Back-end developed with NodeJS and SQL connected to a MySQL database. Admin panel displayed on `,
      `YouTube`,
      `Full-stack practice project with front-end built in React using Redux, Axios and Bootstrap. Back-end developed with NodeJS and connected to a MongoDB database. Code: `,
      `PHP project, developed using HTML, Bootstrap and SQL connected to a MySQL database. Admin Panel for Administrators.`,
      `Various Other Projects`,
      `Part of projects developed using programming languages and technologies within my skill set. All projects are available on my `,
      `GitHub profile.`,
      `Education`,
      `High School: Mihajlo Pupin, Kikinda`,
      `Field of study: Mechanical Technician`,
      `About Me`,
      `Born in 1969, I have always had a passion for computers and an interest in both software and hardware. In 2020, I created my first website using WordPress and since then, I actively started studying programming. I fell in love with it so much, that I devoted all of my free time to learning, watching programming courses and working on projects. I have progressively learned multiple programming languages and technologies, starting from courses by `,
      `Krojačeva Škola, `,
      `Danilo Vešović, `,
      `Milan Tair, `,
      `Udemy, `,
      `and more. As of March 2024, I have 174 projects on my `,
      `GitHub `,
      `profile.`,
      `Skills`,
      `Languages`,
      `Serbian - Native`,
      `English - A1`,
      `en`,
    ],
    footer: [`All Rights Reserved.`],
  },
  srb: {
    navigation: [
      `Početna`,
      `O meni`,
      `Veštine`,
      `Projekti`,
      `Kontakt`,
      `Komentari`,
    ],
    home: [
      `Dobro jutro`,
      `Dobar dan`,
      `Dobro veče`,
      `i dobrodošli na moj portfolio vebsajt. Moje ime je Goran Kekez a nadimak Maxi. Ja sam Junior Full Stack Developer. Za više informacija o meni kliknite na dugme "Pročitaj više".`,
      `Pročitaj više`,
      `srb`,
    ],
    about: [
      `O meni`,
      `Dobrodošli na moj portfolio sajt`,
      `Moje ime je Goran Kekez a nadimak Maxi. Kodirao sam ovaj sajt da bih se predstavio poslodavcima koji su u potrazi za web programerima, pokušaću da predstavim sebe i svoje do sada stečeno znanje.`,
      `Rodjen sam maja 1969 godine, živim u Srbiji grad Kikinda. Zavšio sam srednju školu "Mihajlo Pupin" Kikinda - mašinski tehničar. Od tinejdzerskih dana se bavim sportom, trenirao sam oko 36 godina KickBoxing i bio sam prvak bivše SFRJ i kasnije R. Srbije, takodje imam trenersku licencu i bio sam trener u istom sportu. I danas sam aktivan i kondiciju održavam u teretani. Imam položene sve kategorije u vozačkoj dozvoli. Imam velikog iskustva u radu sa ljudima pošto sam radio kao saobraćajni policajac a trenutno sam zaposlen kao pravosudni stražar, radnik sam Ministarstva Pravde R.Srbije u Osnovnom sudu u Kikindi gde radim već 17 godina.`,
      `Od 2001 godine kada sam kupio svoj prvi računar zaljubio sam se u računarstvo. Podizao sam windows operativne sisteme, instalirao razne programe, sklapao različite harvere u jedan računar kako za prijatelje tako i za klijente koji su me angažovali. U 2020 godini sam slučajno uradio svoj prvi web sajt preko CMS WordPress-a. To mi se jako dopalo ali sam bio znatiželjan i hteo sam da bez CMS-a sam iskodiram jedan sličan web sajt. Tako da sam počeo preko YouTuba gledajući prve tutorijale kod `,
      `Danila Vešovića `,
      `o HTML i CSS-u, dalje sam saznao za `,
      `Krojačevu Školu `,
      `gde sam naučio dosta o HTML, CSS-u i JavaSriptu i o dosta drugih stvari potrebnim za programiranje. Tu sam stekao i dosta licenci koje posedujem a moj profil kod njih pogledajte `,
      `ovde. `,
      `Uporedo sa njima gledao sam i pratio na YouTube predavanja `,
      `Milana Taira `,
      `gde čovek koji ima ogromno znanje nesebično deli sa svojim pratiocima. Kada sam naučio HTML,CSS, JavaScript itd... počeo sam da učim ReactJS, PHP, NodeJS i malo naprednije stvari kod Danila Vešovića u njegovoj `,
      `školi programiranja `,
      `gde sam ponovio već naučeno i naučio nove tehnologije. Detaljije pogledajte na stranici `,
      `veštine. `,
      `A klikom na svaku veštinu otvoriće vam se gde sam je naučio i sertifikat koji sam dobio.`,
      `Ovaj porfolio sajt sam uradio u React-u, koristio sam Redux, Axios, Toastify, SASS, Bootstrap itd... za frontend a za backend sam koristio Node, bcriptjs za kodiranje lozinke, mongoose i mongodb za bazu podataka da bih sačuvao korisničke podatke i napisane utiske na stranici `,
      `Komentari. `,
      `Da bi ste napisali utisak prvo se morate `,
      `registrovati `,
      `ako vec niste zatim vam na email stiže konfirmacioni email za potvrdu vaše unete adrese i posle toga se možete `,
      `ulogovati. `,
      `Ako zaboravite svoju lozinku mozete je `,
      `zatražiti ponovo `,
      `i to će vam takodje stići na vašu email adresu. Ako slučajno ne možete pronaći poslati email sa ovog sajta proverite spam poruke, nekad ih gogle smesti tamo. Dizajn sam takodje sam osmislio i originalan je, potrudio sam se da svaka stranica bude različita i jedinstvena na svoj način dodajući razne animacije elementima.`,
      `Stranica `,
      `Moji Projekti `,
      `vam pokazuje samo deo projekata koje sam uradio neke vežbajući a neke naručene od klijenta. Klikom na link ka sajtu otvara vam se novi tab da pogledate sajt uživo, u opisu projekta sam ukratko opisao koje sam programske jezike i tehnologije koristio kodirajući taj projekat. Na nekima možete videti i kod koji vodi ka mom `,
      `GitHub `,
      `nalogu a na nekima je kod privatan pa ga nemožete videti zbog privatnosti klijenta. Do sada 03/24 na svom `,
      `GitHub `,
      `nalogu imam 174 projekta. Potrudiću se da ih bude još više.`,
      `Ako želite ostavite svoj utisak kako vam se svidja sajt, slobodno ga `,
      `napišite. `,
      `Oduzeće vam samo par minuta a meni će puno značiti. Hvala vam unapred.`,
      `Imam veliku želju da se prekvalifikujem i radim ono što najviše volim a to je da kodiranje, dalje usavršavanje stečenog znanja kao i učenje novih programskih jezika i tehnologija.`,
      `Ako smatrate da ispunjavam vaše kriterijume i da bih bio dobar za vaš tim, biće mi čast da kliknite na `,
      `balon CV `,
      `koji lebdi od dna ka vrhu stranice O meni.`,
    ],
    skills: [`VEŠTINE`],
    projects: [
      `Moji Projekti`,
      `Hosting podešen na `,
      `Kod: `,
      `privatni repo na GitHub-u`,
      `repo na GitHub-u`,
      `Backend: `,
      `Frontend: `,
      `Link ka sajtu: `,
      `Full stack Web-Shop, frontend radjen u React-u korisćen Redux, Axios, SCSS, Bootstrap.`,
      `Backend radjen sa NodeJS i SQL povezan sa MySQL bazom podataka.`,
      `Contra Team-u`,
      `YouTube prikaz Admin panela`,
      `Vežba Full stack projekat, frontend radjen u React-u korisćen Redux, Axios, SCSS, Bootstrap.`,
      `Backend radjen sa NodeJS i povezan sa MongoDB bazom podataka.`,
      `Projekat radjen u PHP-u. Korišćeni HTML, Bootstrap i SQL povezan sa MySQL bazom podataka. Admin Panel za Administratore.`,
      `Projekat radjen u PHP-u. Korišćeni HTML, Bootstrap i SQL povezan sa MySQL bazom podataka.`,
      `Projekat radjen u React-u. Korišćen Bootstrap. Dvojezični sajt EN i SRB.`,
      `Projekat radjen u React-u. Baza podataka povezana sa `,
      `Projekat radjen u React-u. Podaci povezani na Localstorage.`,
      `Projekat radjen u React-u, korišćen je Bootstrap.`,
      `Projekat radjen u React-u.`,
      `Api podaci povlače se sa GitHub-a.`,
      `Projekat radjen u HTML, CSS i vanila JavaScript.`,
      `Podaci se čuvaju na Localestorage.`,
      `Podešavanja se čuvaju na Localestorage.`,
      `Projekat radjen u HTML, CSS, Bootstrap i vanila JavaScript.`,
      `Podaci za statistiku se povlače sa API `,
      `Podaci se povlače sa API `,
      `Podaci povezani na Localstorage.`,
      `Projekat radjen u HTML, CSS i Bootstrap.`,
      `Projekat radjen u HTML i CSS-u.`,
      `Za pripadnike Ministarstva pravde Pravosudne straže R.Srbije`,
      `Projekat radjen u HTML i TailwindCSS-u.`,
      `Moj prvi rad u html i css-u`,
      `Sajt radjen u WordPress-u kompletno uredjen sa Elementor-om.`,
    ],
    contact: [
      `Kontakt`,
      `Ime`,
      `Petar`,
      `Prezime`,
      `Petrovic`,
      `petarpetrovic@gmail.com`,
      `Broj telefona`,
      `Poruka`,
      `Ovde upisati poruku...`,
      `Pošalji`,
      `Ime je obavezno!`,
      `Prezime je obavezno!`,
      `Email je obavezan!`,
      `Poruka je obavezna!`,
    ],
    verification: [
      `Ti si čovek!`,
      `Pritisni "Prihvati" dugme!`,
      `Potvrdite da ste čovek!`,
      `Poruka je poslata!`,
      `Izaberite heksadecimalnu boju:`,
      `za verifikaciju!`,
      `Da li si čovek?`,
      `Prihvati`,
    ],
    comments: [
      `Knjiga Utisaka`,
      `Kliknite na knjigu da biste ostavili komentar.`,
      `Prijavite se da bi ostavili komentar.`,
    ],
    login: [
      `Izaberite način prijave:`,
      `Prijavite se preko Google naloga`,
      `Prijavite se preko Email adrese`,
      `Nazad`,
    ],
    email: [
      `Prijavite se preko e-mail adrese`,
      `Prijavi se`,
      `Zaboravljena šifra?`,
      `Nemate nalog?`,
      `Registracija`,
      `Nazad`,
      ` polje je obavezno!`,
      `Upišite ispravnu mail adresu!`,
      `Šifra mora da sadrži: min 8 karaktera, min 1 spec znak, min 1 vel slovo i min 1 malo slovo!`,
      `Uspešno ste prijavljeni!`,
    ],
    google: [`Nazad`, `Uspešno ste prijavljeni!`],
    registration: [
      `Registracija preko e-mail adrese`,
      `Ime`,
      `Petar`,
      `Prezime`,
      `Petrović`,
      `petarpetrovic@gmail.com`,
      `Šifra`,
      `vaša šifra`,
      `Ponovite šifru`,
      `Pogledaj detalje`,
      `Nazad`,
      `Popunite sva polja!`,
      `Ime mora sadržati min 2 a max 15 karaktera!`,
      `Prezime mora sadržati min 2 a max 15 karaktera!`,
      `Upišite ispravnu mail adresu!`,
      `Šifra mora da sadrži: min 8 karaktera, min 1 spec znak, min 1 vel slovo i min 1 malo slovo!`,
      `Ponovite istu šifru!`,
    ],
    confirmation: [
      `Vaša registracija:`,
      `Ime:`,
      `Prezime:`,
      `Pošalji`,
      `Nazad`,
      `Proverite email - potvrdite registraciju!`,
    ],
    forgot_password: [
      `Zatražite promenu vaše šifre`,
      `Unesite vašu e-mail adresu`,
      `petarpetrovic@gmail.com`,
      `Nakon slanja proverite vaše e-mail poruke.`,
      `Pošalji`,
      `Nazad`,
      `Polje ne sme biti prazno!`,
      `Unesite isravnu e-mail adresu!`,
    ],
    reset_password: [
      `Reset korisničke šifre`,
      `Kreirajte novu šifru:`,
      `Nova šifra`,
      `upišite novu šifru`,
      `Ponovite novu šifru`,
      `ponovite novu šifru`,
      `Sačuvajte promene`,
      `Polje ne sme biti prazno!`,
      `Šifra mora da sadrži: min 8 karaktera, min 1 spec znak, min 1 vel slovo i min 1 malo slovo!`,
      `Ponovite istu šifru!`,
    ],
    user_acc: [
      `Korisnički nalog`,
      `Odjavite se`,
      `Nazad`,
      `Obrišite nalog`,
      `Google nalog`,
      `Email nalog`,
      `Ime`,
      `unesite novo ime`,
      `Prezime`,
      `unesite novo prezime`,
      `Šifra`,
      `unesite novu šifru`,
      `Ponovite šifru`,
      `ponovite novu šifru`,
      `Pošaljite izmene`,
      `Prvo napravite izmene!`,
      `Ime mora sadržati min 2 a max 15 karaktera!`,
      `Prvo promenite Ime!`,
      `Prezime mora sadržati min 2 a max 15 karaktera!`,
      `Prvo promenite Prezime!`,
      `Šifra mora da sadrži: min 8 karaktera, min 1 spec znak, min 1 vel slovo i min 1 malo slovo!`,
      `Ponovite istu šifru!`,
      `Uspešna odjava!`,
    ],
    activate_acc: [
      `Aktivacija naloga`,
      `Uspešno aktiviran nalog. Preusmeravanje na stranicu za prijavu...`,
    ],
    delete_acc: [
      `Brisanje Naloga:`,
      `Da li zaista želite da obrišete svoj nalog?`,
      `Obriši`,
      `Nazad`,
    ],
    impression: [
      `Ostavite svoj utisak`,
      `Vaš komentar:`,
      `Unesite tekst do maksimalno 700 karaktera...`,
      `Pošaljite`,
      `Nazad`,
      `Komentar je poslat!`,
    ],
    cv_page: [
      `Preuzmi CV`,
      `Kikinda, Srbija`,
      `Praksa`,
      `Projekti`,
      `Full stack Web-Shop, frontend radjen u React-u korisćen Redux, Axios, SCSS, Bootstrap. Backend radjen sa NodeJS i SQL povezan sa MySQL bazom podataka. Admin panel prikazan na `,
      `YouTub-u`,
      `Vežba Full stack projekat, frontend radjen u React-u korisćen Redux, Axios, SCSS, Bootstrap. Backend radjen sa NodeJS i povezan sa MongoDB bazom podataka. Kod: `,
      `Projekat radjen u PHP-u. Korišćeni HTML, Bootstrap i SQL povezan sa MySQL bazom podataka. Admin Panel za Administratore.`,
      `Još mnogi drugi projekti`,
      `Deo projekta radjenih jezicima i tehnologijama sa kojim raspolažem, a svi projekti koje imam su na `,
      `GitHub-u.`,
      `Edukacija`,
      `Srednja škola "Mihajlo Pupin" Kikinda`,
      `Zanimanje: Mašinski Tehničar`,
      `O meni`,
      `Rodjen sam 1969 godine, oduvek sam voleo računare i imao interesovanje ka softveru i hardveru. 2020 godine sam uradio svoj prvi web sajt u WordPress-u i od tada sam počeo veoma aktivno da učim programiranje. Toliko sam ga zavoleo da sam bukvalno sve svoje slobodno vreme provodio gledajući razne kurseve programiranja i radio na projektima. Učio sam sve više programskih jezika i razne tehnologije. Počeo sam u `,
      `Krojačevoj Školi `,
      `pa preko Danila Vešovića, `,
      `Milana Taira, `,
      `Udemy-a `,
      `itd... Trenutno (03/24) imam 174 projekta na svom `,
      `GitHub `,
      `nalogu.`,
      `Veštine`,
      `Jezici`,
      `Srpski - maternji`,
      `Engleski - A1`,
      `srb`,
    ],
    footer: [`Sva prava zadržana.`],
  },
};
export { data };
