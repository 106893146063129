import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const userSlicer = createSlice({
  name: 'user',
  // exported reducer
  initialState: initialState,
  // define all actions
  reducers: {
    saveUser(state, data) {
      // console.log('data from userSlicer-->', data.payload);
      state.user = data.payload;
    },
    removeUser(state) {
      state.user = null; // umesto null mozemo da pisemo initialState (od gore)
    },
  },
});

export const { saveUser, removeUser } = userSlicer.actions;
export default userSlicer.reducer;