import React from 'react'
import { Link } from 'react-router-dom';

const Seo = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>SEO</h2>
      <p>YouTube</p>
      <p>
        <Link
          to="https://www.youtube.com/results?search_query=seo"
          target="_blank"
          rel="noopener noreferrer"
        >
          Razni tutorijali na YuoTube
        </Link>
      </p>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/36805/google-analytics-povezivanje-sa-sajtom/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics, povezivanje sa sajtom
        </Link>
      </p>
    </div>
  );
}

export default Seo;