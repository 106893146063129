import React from 'react'
import { Link } from 'react-router-dom';

const Node = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>Node JS</h2>
      <p>Danilo Vešović</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/nodejs-semestar1"
          target="_blank"
          rel="noopener noreferrer"
        >
          NodeJs - Semestar 1
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/nodejs-semestar2"
          target="_blank"
          rel="noopener noreferrer"
        >
          NodeJs - Semestar 2
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/nodejs-semestar3"
          target="_blank"
          rel="noopener noreferrer"
        >
          NodeJs - Semestar 3
        </Link>
      </p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/levelup-fullstack"
          target="_blank"
          rel="noopener noreferrer"
        >
          LevelUp FullStack
        </Link>
      </p>
      <p>Milan Tair</p>
      <p>
        <Link
          to="https://www.youtube.com/watch?v=HjOeA5heVpg&list=PL-UTrxF0y8kKGrBg1OaBhoP8PWdJqR5Qg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Osnove HTML, CSS i JavaScript - 100 lekcija
        </Link>
      </p>
    </div>
  );
}

export default Node;