import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import playClick from '../../assets/mp3/mixkit-modern-technology-select-3124.wav';

const Top = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hamburgerRef = useRef(null);
  const audioRef = useRef(null);

  const changeHamburgerMenu = () => {
    const mainNav = document.querySelector('.main-nav');
    setIsMenuOpen(!isMenuOpen);
    const hamburgerElement = hamburgerRef.current;

    if (isMenuOpen) {
      mainNav.style.display = 'none';
      hamburgerElement.setAttribute('id', 'hamburger-menu');
    } else {
      mainNav.style.display = 'block';
      hamburgerElement.setAttribute('id', 'close-hamburger');
    }
  };

  return (
    <div className="logo-social">
      <audio ref={audioRef} src={playClick} style={{ display: 'none' }} />
      <Link className="logo" to="/">
        Goran Kekez | Portfolio
      </Link>
      <ul className="social-holder list-reset">
        <li>
          <Link
            id="facebook"
            to="https://www.facebook.com/groups/834282170476853"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </Link>
        </li>
        <li>
          <Link
            id="viber"
            to="https://invite.viber.com/?g2=AQBLKJhSSGanPUy1ApgA4Fvl9VGEwrXJL%2F%2FjgbbKD2HOycDfNfT2%2F%2Fm1R7PJgbfw&lang=sr"
            target="_blank"
            rel="noopener noreferrer"
          >
            Viber
          </Link>
        </li>
        <li>
          <Link
            id="github"
            to="https://github.com/Maxi69K"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </Link>
        </li>
        <li>
          <Link
            id="discord"
            to="https://discord.gg/Dh9T6s4bsj"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </Link>
        </li>
        <li>
          <Link
            id="linkedin"
            to="https://www.linkedin.com/in/goran-kekez-01688a203/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </Link>
        </li>
        <li>
          <Link
            id="twitter"
            to="https://twitter.com/Maxi69K"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </Link>
        </li>
        <li>
          <Link
            id="codepen"
            to="https://codepen.io/Maxi69"
            target="_blank"
            rel="noopener noreferrer"
          >
            Codepen
          </Link>
        </li>
      </ul>
      <div
        id="hamburger-menu"
        ref={hamburgerRef}
        onClick={(e) => {changeHamburgerMenu(e); audioRef.current.play();}}
      ></div>
      <Navigation
        isMenuOpen={isMenuOpen}
        changeHamburgerMenu={changeHamburgerMenu}
      />
    </div>
  );
};

export default Top;
