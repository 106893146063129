import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import letterImg from '../../assets/img/letter-white.svg';
import { Outlet, useNavigate } from 'react-router-dom';
import { saveMessage } from '../../redux/message.slicer';
import { data } from '../data/Data.js';

const ContactForm = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [inputData, setInputData] = useState({});
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.hasOwnProperty('maxi_portfolio_user')) {
      setUser(JSON.parse(localStorage.getItem('maxi_portfolio_user')));
    }
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  const onInputHandler = (e) => {
    let copyInputData = { ...inputData };
    copyInputData[e.target.name] = e.target.value.trim();
    if (Object.keys(user).length > 0) {
      copyInputData.first_name = user.first_name;
      copyInputData.last_name = user.last_name;
      copyInputData.email = user.email;
    }
    setInputData(copyInputData);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!inputData.first_name && !Object.keys(user).length > 0) {
      toast.error(`${data[language].contact[10]}`);
    } else if (!inputData.last_name && !Object.keys(user).length > 0) {
      toast.error(`${data[language].contact[11]}`);
    } else if (!inputData.email && !Object.keys(user).length > 0) {
      toast.error(`${data[language].contact[12]}`);
    } else if (!inputData.message) {
      toast.error(`${data[language].contact[13]}`);
    } else {
      dispatch(saveMessage(inputData));
      navigate('/verification');
    }
  };

  return (
    <div className="row-contact">
      <div className="matrix-holder">
        <div className="matrix matrix1"></div>
        <div className="matrix matrix2"></div>
        <div className="matrix matrix3"></div>
        <div className="matrix matrix4"></div>
        <div className="matrix matrix5"></div>
        <div className="matrix matrix6"></div>
        <div className="matrix matrix7"></div>
        <div className="matrix matrix8"></div>
        <div className="matrix matrix9"></div>
      </div>
      <div className="contact-holder">
        <form id="contact-form" onSubmit={submitHandler}>
          <div className="img-holder">
            <img
              // src={require('../../assets/img/letter-white.svg').default}
              src={letterImg}
              alt="Message"
            />
          </div>
          <h1 className="contact-text">{data[language].contact[0]}</h1>
          <div className="form-group">
            <label htmlFor="first_name">
              {data[language].contact[1]}
              <span>*</span>
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control"
              id="first_name"
              placeholder={data[language].contact[2]}
              maxLength="30"
              readOnly={user.first_name ? true : false}
              defaultValue={user.first_name || ''}
              onInput={onInputHandler}
            />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">
              {data[language].contact[3]}
              <span>*</span>
            </label>
            <input
              type="text"
              name="last_name"
              className="form-control"
              id="last_name"
              placeholder={data[language].contact[4]}
              maxLength="30"
              readOnly={user.last_name ? true : false}
              defaultValue={user.last_name || ''}
              onInput={onInputHandler}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">
              E-mail<span>*</span>
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              placeholder={data[language].contact[5]}
              maxLength="40"
              readOnly={user.email ? true : false}
              defaultValue={user.email || ''}
              onInput={onInputHandler}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">{data[language].contact[6]}</label>
            <input
              type="number"
              name="phone"
              className="form-control"
              id="phone"
              placeholder="060123123"
              onInput={onInputHandler}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">
              {data[language].contact[7]}
              <span>*</span>
            </label>
            <textarea
              name="message"
              id="message"
              className="form-control"
              placeholder={data[language].contact[8]}
              onInput={onInputHandler}
            ></textarea>
          </div>
          <button className="btn send-contact-form" type="submit">
            {data[language].contact[9]}
          </button>
        </form>
      </div>
      <div className="matrix-holder">
        <div className="matrix matrix11"></div>
        <div className="matrix matrix12"></div>
        <div className="matrix matrix13"></div>
        <div className="matrix matrix14"></div>
        <div className="matrix matrix15"></div>
        <div className="matrix matrix16"></div>
        <div className="matrix matrix17"></div>
        <div className="matrix matrix18"></div>
        <div className="matrix matrix19"></div>
      </div>
      <Outlet />
    </div>
  );
};

export default ContactForm;
