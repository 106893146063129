import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import Loader from './components/loader/Loader';
import { GoogleOAuthProvider } from '@react-oauth/google';
import epicMusic from './assets/mp3/epic-dramatic-action-trailer-99525.mp3';
import { FaPlay } from 'react-icons/fa6';
import { FaPause } from 'react-icons/fa6';

const googleOAuth = process.env.REACT_APP_GOOGLE_OAUTH;
const baseURL = process.env.REACT_APP_BASE_URL;

// Base URL
axios.defaults.baseURL = baseURL;

function App() {
  const [audioPaused, setAudioPaused] = useState(false);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    const sound = new Audio(epicMusic);
    sound.loop = true;
    setAudio(sound);
  }, []);

  const toggleAudio = () => {
    if (audio && audio.paused) {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
          })
          .catch((error) => {
            console.error('Error playing sound:', error);
          });
      }
    } else if (audio) {
      audio.pause();
    }
    setAudioPaused(!audioPaused);
  };

  return (
    <GoogleOAuthProvider clientId={googleOAuth}>
      <ToastContainer position="bottom-center" theme="dark" />
      <Loader />
      <Nav />
      <Outlet />
      <Footer />
      <button
        className="btn btn-outline-secondary play-btn"
        onClick={toggleAudio}
      >
        {audioPaused ? <FaPause /> : <FaPlay />}
      </button>
    </GoogleOAuthProvider>
  );
}

export default App;
