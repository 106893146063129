import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
};

const messageSlicer = createSlice({
  name: 'message',
  // exported reducer
  initialState: initialState,
  // define all actions
  reducers: {
    saveMessage(state, data) {
      // console.log('data from messageSlicer-->', data.payload);
      state.message = data.payload;
    },
  },
});

export const { saveMessage } = messageSlicer.actions;
export default messageSlicer.reducer;
