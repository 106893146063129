import React from 'react'
import { Link } from 'react-router-dom';

const Html = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>HTML</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/42894/html/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          HTML
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/levelup-fullstack"
          target="_blank"
          rel="noopener noreferrer"
        >
          LevelUp FullStack
        </Link>
      </p>
      <p>Milan Tair</p>
      <p>
        <Link
          to="https://www.youtube.com/watch?v=HjOeA5heVpg&list=PL-UTrxF0y8kKGrBg1OaBhoP8PWdJqR5Qg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Osnove HTML, CSS i JavaScript - 100 lekcija
        </Link>
      </p>
    </div>
  );
}

export default Html;