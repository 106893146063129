import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleModal } from '../../redux/modal.slicer';
import { saveUser } from '../../redux/user.slicer';
import { FaEye } from 'react-icons/fa';
import { data } from '../data/Data.js';

const Register = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_repeat: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  const handleRegisterObj = (e) => {
    let inputValue = e.target.value.trim();
    let inputName = e.target.name;
    let copyUser = { ...user };
    copyUser[inputName] = inputValue;
    setUser(copyUser);
  };

  const onRegisterSubmit = (e) => {
    e.preventDefault();
    const regexPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&$^*()-_=+.,~|])(?=.{8,})/;
    const regexEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    // Validation
    if (
      user.first_name === '' ||
      user.last_name === '' ||
      user.email === '' ||
      user.password === ''
    ) {
      return toast.error(`${data[language].registration[11]}`);
    }
    if (user.first_name.length <= 2 || user.first_name.length > 15) {
      return toast.error(`${data[language].registration[12]}`);
    }
    if (user.last_name.length <= 2 || user.last_name.length > 15) {
      return toast.error(`${data[language].registration[13]}`);
    }
    if (user.email.match(regexEmail) === null) {
      return toast.error(`${data[language].registration[14]}`);
    }
    if (user.password.match(regexPassword) === null) {
      return toast.error(`${data[language].registration[15]}`);
    }
    if (user.password !== user.password_repeat) {
      return toast.error(`${data[language].registration[16]}`);
    }

    // Remove password_repeat
    let copyUser = { ...user };
    delete copyUser.password_repeat;
    dispatch(saveUser(copyUser));
    dispatch(toggleModal(true));
    navigate('/register-user/confirm-register');
  };

  const showHidePassword = () => {
    const passwordType1 = document.querySelector('input[name="password"]');
    const passwordType2 = document.querySelector(
      'input[name="password_repeat"]'
    );
    const btnShowHide = document.getElementById('show-hide-btn');
    if (
      passwordType1.type === 'password' &&
      passwordType2.type === 'password'
    ) {
      passwordType1.type = 'text';
      passwordType2.type = 'text';
      btnShowHide.style.color = 'red';
      btnShowHide.style.textDecoration = 'none';
    } else {
      passwordType1.type = 'password';
      passwordType2.type = 'password';
      btnShowHide.style.color = '#FF8000';
      btnShowHide.style.textDecoration = 'line-through';
    }
  };

  return (
    <div className="container register-page">
      <div className="register-page-holder">
        <h1 className="fs-5 text-center mb-5">
          {data[language].registration[0]}
        </h1>
        <form autoComplete="off" onSubmit={onRegisterSubmit}>
          <div className="mb-3">
            <label htmlFor="first_name" className="form-label">
              {data[language].registration[1]}
            </label>
            <input
              placeholder={data[language].registration[2]}
              type="text"
              className="form-control"
              id="first_name"
              name="first_name"
              onChange={(e) => handleRegisterObj(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="last_name" className="form-label">
              {data[language].registration[3]}
            </label>
            <input
              placeholder={data[language].registration[4]}
              type="text"
              className="form-control"
              id="last_name"
              name="last_name"
              onChange={(e) => handleRegisterObj(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              placeholder={data[language].registration[5]}
              type="email"
              className="form-control"
              id="email"
              name="email"
              onChange={(e) => handleRegisterObj(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              {data[language].registration[6]}
            </label>
            <input
              placeholder={data[language].registration[7]}
              type="password"
              className="form-control"
              id="password"
              name="password"
              onChange={(e) => handleRegisterObj(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password-repeat" className="form-label">
              {data[language].registration[8]}
            </label>
            <input
              placeholder={data[language].registration[7]}
              type="password"
              className="form-control"
              id="password-repeat"
              name="password_repeat"
              onChange={(e) => handleRegisterObj(e)}
            />
          </div>
          <div className="hide-show-password-holder">
            <button
              className="btn"
              type="button"
              id="show-hide-btn"
              onClick={showHidePassword}
            >
              <FaEye />
            </button>
          </div>
          <button type="submit" className="btn btn-send form-control ">
            {data[language].registration[9]}
          </button>
        </form>
      </div>
      <Link className="btn btn-outline-secondary" to="/email-login">
        {data[language].registration[10]}
      </Link>
      <Outlet />
    </div>
  );
};

export default Register;
