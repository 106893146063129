import React from 'react'
import { Link } from 'react-router-dom';

const ContactForm = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>Contact Form</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/44182/praktican-rad-kontakt-forma/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Praktičan rad: Kontakt forma
        </Link>
      </p>
    </div>
  );
}

export default ContactForm;