import React, { useEffect, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setUserToLocalStorage } from '../../services/auth.service';
import { data } from '../data/Data.js';
import { useSelector } from 'react-redux';

const Google = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const navigate = useNavigate();

  useEffect(() => {
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [language, languageStore]);

  return (
    <div className="container google-page">
      <div className="google-page-holder">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const decoded = jwtDecode(credentialResponse.credential);
            const user = {
              first_name: decoded.given_name,
              last_name: decoded.family_name,
              email: decoded.email,
            };

            setUserToLocalStorage(user);
            toast.success(`${data[language].google[1]}`);
            navigate('/comments');
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </div>
      <Link className="btn btn-outline-secondary" to="/comments/login">
        {data[language].google[0]}
      </Link>
    </div>
  );
};

export default Google;
