import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPhoneVolume } from 'react-icons/fa6';
import { FaHome, FaLinkedin, FaGithub } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Chingu from '../assets/img/Chingu.png';
import Maxi from '../assets/img/Maxi_logo.webp';
import CV_PDF_EN from '../assets/pdf/cv_gorankekez_en.pdf';
import CV_PDF_SRB from '../assets/pdf/cv_gorankekez_srb.pdf';
import { data } from '../components/data/Data';
import playClick from '../assets/mp3/mixkit-select-click-1109.wav';

const CvPage = () => {
  const [language, setLanguage] = useState('en');
  const audioRef = useRef(null);

  useEffect(() => {
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, []);

  // Change Language
  const changeLanguage = (e) => {
    if (e.target.getAttribute('class') === 'srb') {
      e.target.setAttribute('class', 'en');
      setLanguage('en');
    } else {
      e.target.setAttribute('class', 'srb');
      setLanguage('srb');
    }
  };

  const handleDownloadCV = () => {
    if (language === 'srb') {
      window.open(CV_PDF_SRB, '_blank');
    } else {
      window.open(CV_PDF_EN, '_blank');
    }
  };

  return (
    <div className="container cv-page">
      <audio ref={audioRef} src={playClick} style={{ display: 'none' }} />
      <div id="language">
        <Link
          onClick={(e) =>  {changeLanguage(e); audioRef.current.play();}}
          className={data[language].cv_page[27]}
        ></Link>
      </div>
      <button
        onClick={handleDownloadCV}
        className="btn btn-outline-secondary cv-btn"
      >
        {data[language].cv_page[0]}
      </button>
      <h1 className="fs-3 text-center my-5">Curriculum Vitae</h1>
      <div className="cv-page-header">
        <div className="title-box">
          <div className="title-box-logo mb-1">
            <img className="img-fluid" src={Maxi} alt="Maxi Logo" />
          </div>
          <h2 className="fs-1 text-light">Goran Kekez</h2>
          <h4 className="text-light">Junior Full Stack Developer</h4>
        </div>
        <div className="contact">
          <div>
            <h5 className="fs-3 d-inline-block me-2">
              <FaHome />
            </h5>
            <h5 className="d-inline-block m-0">
              <Link
                className="d-block text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                to="https://maps.app.goo.gl/SrN5Kc6A13EAr3rh6"
              >
                {data[language].cv_page[1]}
              </Link>
            </h5>
          </div>
          <div>
            <h5 className="d-inline-block me-2">
              <FaPhoneVolume />
            </h5>
            <h5 className="d-inline-block">
              <Link
                className="d-block text-decoration-none"
                to="tel:+381637014015"
              >
                +381637014015
              </Link>
            </h5>
          </div>
          <div>
            <h5 className="fs-3 d-inline-block me-2">
              <MdEmail />
            </h5>
            <h5 className="d-inline-block">
              <Link
                className="d-block text-decoration-none"
                to="mailto:webdevelopermaxi@gmail.com"
              >
                webdevelopermaxi@gmail.com
              </Link>
            </h5>
          </div>
          <div>
            <h5 className="fs-3 d-inline-block me-2">
              <FaLinkedin />
            </h5>
            <h5 className="d-inline-block m-0">
              <Link
                className="d-block text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.linkedin.com/in/goran-kekez-01688a203"
              >
                Goran Kekez
              </Link>
            </h5>
          </div>
          <div>
            <h5 className="fs-3 d-inline-block me-2">
              <FaGithub />
            </h5>
            <h5 className="d-inline-block m-0">
              <Link
                className="d-block text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                to="https://github.com/Maxi69K"
              >
                Maxi69K
              </Link>
            </h5>
          </div>
        </div>
      </div>
      <div className="cv-page-body">
        <div className="experience">
          <h2 className="fs-5 text-secondary">{data[language].cv_page[2]}</h2>
          <div className="chingu-holder">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://www.chingu.io"
            >
              <img className="img-fluid" src={Chingu} alt="Chingu" />
            </Link>
          </div>
          <div>
            <h5 className="fs-3 d-inline-block me-2">
              <FaGithub />
            </h5>
            <h5 className="d-inline-block m-0">
              <Link
                className="d-block text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
                to="https://github.com/chingu-voyages"
              >
                chingu-voyages
              </Link>
            </h5>
          </div>
          <ul>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://drive.google.com/file/d/1FfQslPN88gfU6h7CcsTwY0KMKamjtFHe/view?usp=sharing"
              >
                Voyage 43 06.03.2023 - 16.04.2023 - Certificate
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://drive.google.com/file/d/1PSQVADG0tDUO4EQF4mynfkYVfdjjLlxl/view?usp=sharing"
              >
                Voyage 42 02.01.2023 - 12.02.2023 - Certificate
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://drive.google.com/file/d/1Ee0T5S7iUzyoFnGXz6-KzC-yBcY4iOVJ/view?usp=sharing"
              >
                Voyage 41 03.10.2022 - 13.11.2022 - Certificate
              </Link>
            </li>
          </ul>
          <h2 className="fs-5 text-secondary">{data[language].cv_page[3]}</h2>
          <ul>
            <li>
              <Link
                className="text-light"
                target="_blank"
                rel="noopener noreferrer"
                to="https://djurdjevic.eu.org"
              >
                Termosolar
              </Link>
            </li>
            <li>
              <p className="m-0">
                {data[language].cv_page[4]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://youtu.be/njijMH4JkEQ"
                >
                  {data[language].cv_page[5]}
                </Link>
              </p>
            </li>
            <li>
              <Link
                className="text-light"
                target="_blank"
                rel="noopener noreferrer"
                to="https://zurea-shop.netlify.app"
              >
                Zurea Shop
              </Link>
            </li>
            <li>
              <p className="m-0">
                {data[language].cv_page[6]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/zurea_shop_be"
                >
                  Backend
                </Link>{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K/zurea_shop_fr"
                >
                  Frontend
                </Link>
              </p>
            </li>
            <li>
              <Link
                className="text-light"
                target="_blank"
                rel="noopener noreferrer"
                to="https://maxidesign.eu.org/social_network"
              >
                Social Network
              </Link>
            </li>
            <li>
              <p className="m-0">{data[language].cv_page[7]}</p>
            </li>
            <li>
              <Link
                className="text-light"
                target="_blank"
                rel="noopener noreferrer"
                to="/projects"
              >
                {data[language].cv_page[8]}
              </Link>
            </li>
            <li>
              <p className="m-0">
                {data[language].cv_page[9]}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://github.com/Maxi69K?tab=repositories"
                >
                  {data[language].cv_page[10]}
                </Link>
              </p>
            </li>
          </ul>
          <h2 className="fs-5 text-secondary">{data[language].cv_page[11]}</h2>
          <ul>
            <li>
              <p className="m-0">{data[language].cv_page[12]}</p>
            </li>
            <li>
              <p className="m-0">{data[language].cv_page[13]}</p>
            </li>
          </ul>
          <h2 className="fs-5 text-secondary">{data[language].cv_page[14]}</h2>
          <ul>
            <li>
              {data[language].cv_page[15]}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/ucenik/maxi"
              >
                {data[language].cv_page[16]}
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.danilovesovic.com"
              >
                {data[language].cv_page[17]}
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.youtube.com/@MilanTair"
              >
                {data[language].cv_page[18]}
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.udemy.com/courses/development/web-development"
              >
                {data[language].cv_page[19]}
              </Link>
              {data[language].cv_page[20]}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://github.com/Maxi69K?tab=repositories"
              >
                {data[language].cv_page[21]}
              </Link>{' '}
              {data[language].cv_page[22]}
            </li>
          </ul>
        </div>
        <div className="skills-cv">
          <h2 className="fs-5 text-secondary">{data[language].cv_page[23]}</h2>
          <ul>
            <li>ReactJS</li>
            <li>Redux</li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/38684/javascript-trilogija-komplet/english"
              >
                JavaScript
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/50582/ajax/english"
              >
                Ajax
              </Link>
            </li>
            <li>NodeJS</li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/40211/php-1/english"
              >
                PHP
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/43650/mysql/english"
              >
                MySQL
              </Link>
            </li>
            <li>MongoDB</li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/40818/bootstrap-4/english"
              >
                Bootstrap
              </Link>
            </li>
            <li>Tailwind</li>
            <li>SASS</li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/43649/css/english"
              >
                CSS
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/42894/html/english"
              >
                HTML
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/44713/git-i-github/english"
              >
                Git&GitHub
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/43750/responsive-dizajn-i-bootstrap-3/english"
              >
                Responsive Design
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/36805/google-analytics-povezivanje-sa-sajtom/english"
              >
                SEO
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/36800/wordpress-instalacija/english"
              >
                WordPress
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/46266/affinity-designer/english"
              >
                Affinity Designer
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.krojacevaskola.com/sertifikati/36799/domen-hosting-cpanel/english"
              >
                Hosting Setup
              </Link>
            </li>
          </ul>
          <h2 className="fs-5 text-secondary">{data[language].cv_page[24]}</h2>
          <ul>
            <li>{data[language].cv_page[25]}</li>
            <li>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://drive.google.com/file/d/11DES6waTWthLNBD3acufzJ5kESCkKPdY/view"
              >
                {data[language].cv_page[26]}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CvPage;
