import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEye } from 'react-icons/fa';
import { passwordChange } from '../../services/user.service';
import { data } from '../data/Data.js';
import { useSelector } from 'react-redux';

const ResetPassword = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [userId, setUserId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!params.user_id) {
      return navigate('/email-login');
    }
    setUserId(params.user_id);
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
  }, [params.user_id, navigate, language, languageStore]);

  const onResetPasswordSubmit = (e) => {
    e.preventDefault();
    const regexPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&$^*()-_=+.,~|])(?=.{8,})/;
    // Validation
    if (newPassword === '') {
      return toast.error(`${data[language].reset_password[7]}`);
    }
    if (newPassword.match(regexPassword) === null) {
      return toast.error(`${data[language].reset_password[8]}`);
    }
    if (newPassword !== passwordRepeat) {
      return toast.error(`${data[language].reset_password[9]}`);
    }
    
    passwordChange(userId, {newPassword})
      .then((res) => {
        toast.success(res.data.message);
        navigate('/email-login');
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const showHidePassword = () => {
    const passwordType1 = document.querySelector('input[name="password"]');
    const passwordType2 = document.querySelector('input[name="password_repeat"]');
    const btnShowHide = document.getElementById('show-hide-btn');
    if (passwordType1.type === 'password' && passwordType2.type === 'password') {
      passwordType1.type = 'text';
      passwordType2.type = 'text';
      btnShowHide.style.color = 'red';
      btnShowHide.style.textDecoration = 'none';
    } else {
      passwordType1.type = 'password';
      passwordType2.type = 'password';
      btnShowHide.style.color = '#FF8000';
      btnShowHide.style.textDecoration = 'line-through';
    }
  };

  return (
    <div className="container reset-password-page mt-5">
      <h1 className="fs-3 text-center">{data[language].reset_password[0]}</h1>
      <div className="col-12 col-sm-8 col-md-6 col-xl-4 m-auto reset-password-page-holder">
        <h2 className="fs-5 text-center my-5">
          {data[language].reset_password[1]}
        </h2>
        <form autoComplete="off" onSubmit={onResetPasswordSubmit}>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              {data[language].reset_password[2]}
            </label>
            <input
              placeholder={data[language].reset_password[3]}
              type="password"
              className="form-control"
              id="password"
              name="password"
              onChange={(e) => setNewPassword(e.target.value.trim())}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password-repeat" className="form-label">
              {data[language].reset_password[4]}
            </label>
            <input
              placeholder={data[language].reset_password[5]}
              type="password"
              className="form-control"
              id="password-repeat"
              name="password_repeat"
              onChange={(e) => setPasswordRepeat(e.target.value.trim())}
            />
          </div>
          <div className="hide-show-password-holder">
            <button
              className="btn"
              type="button"
              id="show-hide-btn"
              onClick={showHidePassword}
            >
              <FaEye />
            </button>
          </div>
          <button type="submit" className="btn btn-send mt-5">
            {data[language].reset_password[6]}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
