import React from 'react';
import App from '../App';
import ErrorPage from '../pages/ErrorPage';
import HomePage from '../pages/HomePage';
import AboutMePage from '../pages/AboutMePage';
import SkillsPage from '../pages/SkillsPage';
import ProjectsPage from '../pages/ProjectsPage';
import CertificatePage from '../pages/CertificatePage';
import ContactPage from '../pages/ContactPage';
import Verification from '../components/contact/Verification';
import CommentsPage from '../pages/CommentsPage';
import LoginPage from '../pages/LoginPage';
import Google from '../components/login/Google';
import EmailLogin from '../components/login/EmailLogin';
import Register from '../components/login/Register';
import ConfirmRegister from '../components/login/ConfirmRegister';
import ActivatePage from '../pages/ActivatePage';
import WriteImpressionPage from '../pages/WriteImpressionPage';
import UserAccountPage from '../pages/UserAccountPage';
import DeleteAccountPage from '../pages/DeleteAccountPage';
import ForgottenPassword from '../components/login/ForgottenPassword';
import ResetPassword from '../components/login/ResetPassword';
import CvPage from '../pages/CvPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';

const routes = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: 'about-me',
        element: <AboutMePage />,
      },
      {
        path: 'skills',
        element: <SkillsPage />,
        children: [
          {
            path: 'certificate/:skill',
            element: <CertificatePage />,
          },
        ],
      },
      {
        path: 'projects',
        element: <ProjectsPage />,
      },
      {
        path: 'contact',
        element: <ContactPage />,
      },
      {
        path: 'comments',
        element: <CommentsPage />,
        children: [
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'write-impression',
            element: <WriteImpressionPage />,
          },
        ],
      },
      {
        path: 'google-login',
        element: <Google />,
      },
      {
        path: 'email-login',
        element: <EmailLogin />,
      },
      {
        path: 'forgotten-password',
        element: <ForgottenPassword />,
      },
      {
        path: 'reset-password/:user_id',
        element: <ResetPassword />,
      },
      {
        path: 'register-user',
        element: <Register />,
        children: [
          {
            path: 'confirm-register',
            element: <ConfirmRegister />,
          },
        ],
      },
      {
        path: 'activate-account/:userId',
        element: <ActivatePage />,
      },
      {
        path: 'user-account',
        element: <UserAccountPage />,
        children: [
          {
            path: 'delete-account',
            element: <DeleteAccountPage />,
          },
        ],
      },
      {
        path: 'terms-and-conditions',
        element: <TermsAndConditionsPage />,
      },
    ],
  },
  {
    path: 'verification',
    element: <Verification />,
  },
  {
    path: 'curriculum-vitae',
    element: <CvPage />,
  },
];

export default routes;
