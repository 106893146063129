import React from 'react';
import { Link } from 'react-router-dom';

const Bootstrap = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>Bootstrap</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/43750/responsive-dizajn-i-bootstrap-3/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Responsive dizajn i Bootstrap 3
        </Link>
      </p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/40818/bootstrap-4/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bootstrap 4
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>Predavač Rade Mrkić</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/bootstrap5"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bootstrap 5
        </Link>
      </p>
    </div>
  );
};

export default Bootstrap;
