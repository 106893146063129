import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { toggleModal } from '../redux/modal.slicer';
import book from '../assets/img/book_impression.webp';
import { getAllImpression } from '../services/user.service';
import { format } from 'date-fns';
import Pagination from '../components/pagination/Pagination'; // Importovanje komponente za paginaciju
import { data } from '../components/data/Data';

const CommentsPage = () => {
  const languageStore = useSelector((state) => state.languageStore.language); // Povlaci sacuvane podatke iz REDUX-a (folder redux)
  const [language, setLanguage] = useState('en');
  const [user, setUser] = useState({});
  const [isLogin, setIsLogin] = useState(false);
  const [impression, setImpression] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Trenutna stranica
  const [totalPages, setTotalPages] = useState(1); // Ukupan broj stranica
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.hasOwnProperty('maxi_portfolio_user')) {
      setUser(JSON.parse(localStorage.getItem('maxi_portfolio_user')));
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    if (languageStore) {
      setLanguage(languageStore);
    }
    if (localStorage.hasOwnProperty('maxi_portfolio_lang')) {
      setLanguage(JSON.parse(localStorage.getItem('maxi_portfolio_lang')));
    }
    getAllImpression(currentPage, 10)
      .then((res) => {
        setImpression(res.data.impressions);
        setTotalPages(res.data.totalPages); // Postavljanje ukupnog broja stranica na osnovu broja komentara (10 komentara po stranici)
      })
      .catch((err) => console.log(err));
  }, [currentPage, language, languageStore]);

  useEffect(() => {
    const checkBoxes = () => {
      const boxes = document.querySelectorAll('.comment-box');
      const triggerBottom = (window.innerHeight / 5) * 4;
      boxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top;
        const boxBottom = box.getBoundingClientRect().bottom;

        // Proveravamo da li je bilo koji deo boxa unutar vidljivog diela prozora
        if (
          (boxTop < triggerBottom && boxBottom > 0) ||
          (boxTop < window.innerHeight && boxBottom > triggerBottom)
        ) {
          box.classList.add('show');
        } else {
          box.classList.remove('show');
        }
      });
    };

    // Dodajemo event listener za skrol
    window.addEventListener('scroll', checkBoxes);

    // Uklanjamo event listener kada se komponenta unmounta
    return () => {
      window.removeEventListener('scroll', checkBoxes);
    };
  }, []); // Pratimo promene samo pri prvom renderu komponente

  const dateFormat = (date) => {
    const formattedDate = format(new Date(date), 'dd.MM.yyyy HH:mm:ss');
    return formattedDate;
  };

  // Funkcija koja se poziva kada se promeni stranica
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderImpression = () => {
    return impression.map((imp) => {
      return (
        <div key={imp._id} className="comment-box my-3 show">
          <p className="m-0 comment-box-text">{imp.impression}</p>
          <div className="d-flex justify-content-between text-secondary">
            <p className="m-0">{dateFormat(imp.date)}</p>
            <p className="m-0">{`${imp.first_name} ${imp.last_name}`}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="container-fluid text-center mt-5 comments-page">
      <h1 className="fs-3 m-0 comment-text">{data[language].comments[0]}</h1>
      <div className="text-center comments-page-book">
        <Link
          onClick={() => dispatch(toggleModal(true))}
          to={isLogin ? '/comments/write-impression' : '/comments/login'}
        >
          <img className="img-fluid" src={book} alt="Book" />
        </Link>
      </div>
      <>
        {isLogin ? (
          <>
            <Link
              to="/user-account"
              className="btn btn-outline-secondary mb-3 log-out-comment"
            >
              {`${user.first_name} ${user.last_name}`}
            </Link>
            <h2 className="fs-6 my-0 text-warning comment-text">
              {data[language].comments[1]}
            </h2>
          </>
        ) : (
          <Link
            className="btn btn-outline-secondary mb-3"
            onClick={() => dispatch(toggleModal(true))}
            to="/comments/login"
          >
            {data[language].comments[2]}
          </Link>
        )}
      </>
      <div className="comments-page-holder mt-3">{renderImpression()}</div>
      
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <Outlet />
    </div>
  );
};

export default CommentsPage;
