import React from 'react'
import { Link } from 'react-router-dom';

const Mysql = () => {
  return (
    <div className="skill-details text-light text-center">
      <h2>MySQL</h2>
      <p>Krojačeva Škola</p>
      <p>
        <Link
          to="https://www.krojacevaskola.com/sertifikati/43650/mysql/english"
          target="_blank"
          rel="noopener noreferrer"
        >
          MySQL
        </Link>
      </p>
      <p>Danilo Vešović</p>
      <p>
        <Link
          to="https://www.danilovesovic.com/kursevi/levelup-fullstack"
          target="_blank"
          rel="noopener noreferrer"
        >
          LevelUp FullStack
        </Link>
      </p>
    </div>
  );
}

export default Mysql;