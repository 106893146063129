import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxPagesToShow = 5;

  // Funkcija koja se poziva kada korisnik klikne na određenu stranicu
  const handleClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  // Funkcija za generisanje paginacije
  const generatePagination = () => {
    let paginationItems = [];

    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (startPage > 1) {
      paginationItems.push(
        <li key={'page-num-start'}>
          <button className="page-link" onClick={() => handleClick(1)}>
            1
          </button>
        </li>
      );
      paginationItems.push(
        <li key={'page-num-ellipsis-start'}>
          <span className="page-link">...</span>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={'page-num-' + i} className={currentPage === i ? 'active' : ''}>
          <button className="page-link" onClick={() => handleClick(i)}>
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      paginationItems.push(
        <li key={'page-num-ellipsis-end'}>
          <span className="page-link">...</span>
        </li>
      );
      paginationItems.push(
        <li key={'page-num-end'}>
          <button className="page-link" onClick={() => handleClick(totalPages)}>
            {totalPages}
          </button>
        </li>
      );
    }

    return paginationItems;
  };

  return (
    <div className="d-flex justify-content-center align-items-center pagination-holder">
      <ul className="pagination m-0">
        <li className={currentPage === 1 ? 'disabled' : ''}>
          <button
            className="page-link"
            onClick={() => handleClick(currentPage - 1)}
          >
            «
          </button>
        </li>
        {generatePagination()}
        <li className={currentPage === totalPages ? 'disabled' : ''}>
          <button
            className="page-link"
            onClick={() => handleClick(currentPage + 1)}
          >
            »
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
